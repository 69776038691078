import { Box} from "@mui/system"




const CryptoDashboard = () => {

    return (
        <Box sx={{ height: "100%" }}>
            <iframe src="https://cryptobizinance.bizio247.com/" style={{
                border: 0,
                width: "100%",
                height: "100%"
            }} />
        </Box>
    )
}



export default CryptoDashboard