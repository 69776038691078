import React, { useState, useEffect } from "react";

import PortfolioSummary from "./PortfolioSummary";
import MarketSummary from "./MarketSummary/MarketSummary";
import SentimentSummary from "./SentimentSummary/SentimentSummary";
import FXDashboard from "./FXDashboard";
import CryptoDashboard from "./CryptoDashboard";
import EquityDashboard from "./EquityDashboard";
import { BrowserRouter, Route, Switch, NavLink, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { AppBar, Tab } from '@mui/material';
import CustomTabs from "../../components/common/CustomTabs"

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';


const MAIN_LINKS = [
  {
    title: "Portfolio Summary",
    link: "/dashboard",
  },
  {
    title: "Market Summary",
    link: "/dashboard/market",
  },
  {
    title: "Sentiment Summary",
    link: "/dashboard/sentiment",
  },
];

const OTHER_LINKS = [
  { title: "FX Dashboard", link: "/dashboard/fx" },
  { title: "Equity Dashboard", link: "/dashboard/equity" },
  { title: "Crypto Dashboard", link: "/dashboard/crypto" },
];

const ROUTES = [
  { path: '/dashboard', component: <PortfolioSummary /> },
  { path: '/dashboard/market', component: <MarketSummary /> },
  { path: '/dashboard/sentiment', component: <SentimentSummary /> },
  { path: '/dashboard/fx', component: <FXDashboard /> },
  { path: '/dashboard/equity', component: <EquityDashboard /> },
  { path: '/dashboard/crypto', component: <CryptoDashboard /> },
]



const OtherMenu = ({ onSelect, OtherLinks }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    !!OtherLinks && setList(OtherLinks);
  }, [OtherLinks])
  return (
    <>
      <Button variant="text" onClick={e=>{setAnchorEl(e.currentTarget)}} endIcon={<AddIcon />}>
        Others
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={e=>setAnchorEl(null)}
      >
        {
          list.map((ol, i) => (
            <MenuItem key={i} onClick={e=>onSelect(i)} >{ol.title}</MenuItem>
          ))
        }
      </Menu>
    </>
  )
}


const DashboardTabsRoutes = ({ location }) => {
  const [data,setData] = useState(MAIN_LINKS);
  const [otherLinks,setOtherLinkData] = useState(OTHER_LINKS) 
  const history = useHistory();


  const onOtherSeletect = (index)=>{
    let temp = [...otherLinks];
    let a = temp.splice(index, 1);
    setOtherLinkData(temp);
    setData([...data,...a])
    history.push(a[0].link);
  }

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <CustomTabs value={location.pathname !== "/" ? location.pathname : "/dashboard"}>
          {
            data.map((_, i) => (
              <Tab key={i} label={_.title} value={_.link} component={NavLink} to={_.link} />
            ))
          }

          {otherLinks.length > 0 &&<OtherMenu OtherLinks={otherLinks} onSelect={onOtherSeletect} />}
        </CustomTabs>
      </AppBar>
      <Switch>
        {
          ROUTES.map((_, i) => (
            <Route exact key={i} path={_.path} render={() => _.component} />
          ))
        }
      </Switch>

    </>
  )
}









const Dashboard = (props) => {
  return (
    <BrowserRouter>
      <Route
        path="/"
        render={(props) => <DashboardTabsRoutes {...props} />}
      />
    </BrowserRouter>
  );


};

export default Dashboard;

