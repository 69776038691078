import { useState, useEffect, useContext } from "react";
import {Paper} from '@mui/material';

import httpService from "../../../common/http.service";
import { WebSocketContext } from "../../../WebSocket";
import DTable from "../../../components/HigherOrderComponent/DTable";



const BondTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { ws, connected } = useContext(WebSocketContext);




  const columns = [
    {
      name: "Name",
      selector: row => row.name
    },
    {
      name: "ISIN",
      selector: row => row.security
    },
    {
      name: "Currency",
      selector: row => row.currency
    },
    {
      name: "Coupon",
      selector: row => row.coupon

    },
    {
      name: "MaturityDate",
      selector: row => row.MaturityDate

    },
    {
      name: "Notional",
      selector: row => row.Notional,
      cell: row => !row.notional || ["", undefined, null, 0, "0"].includes(notional) ? "-" : notional

    },
    {
      name: "Price",
      selector: row => row.price,
      cell: row => !row.price || ["", undefined, null, 0, "0"].includes(price) ? "-" : price
    },
    {
      name: "Override",
      cell: (row) => (
        <input style={{width:"100%"}} />
      ),

    },
    {
      name: "CurrOvrd",
      selector: row => row.CurrOvrd,
      cell: row => !row.CurrOvrd || ["", undefined, null, 0, "0"].includes(CurrOvrd) ? "-" : CurrOvrd
    },
    {
      name: "Yield",
     
      selector: row => row.CurrOvrd,
      cell: row => !row.CurrOvrd || ["", undefined, null, 0, "0"].includes(CurrOvrd) ? "-" : CurrOvrd
    },
    {
      name: "Dur",
     
      selector: row => row.Dur,
      cell: row => !row.Dur || ["", undefined, null, 0, "0"].includes(Dur) ? "-" : Dur
    },
    {
      name: "DV01",
     
      selector: row => row.DV01,
      cell: row => !row.DV01 || ["", undefined, null, 0, "0"].includes(DV01) ? "-" : DV01
    },
    {
      name: "Basis",
      selector: row => row.Basis,
      cell: row => !row.Basis || ["", undefined, null, 0, "0"].includes(Basis) ? "-" : Basis

    },
    {
      name: "ZSpread",
     
      selector: row => row.ZSpread,
      cell: row => !row.ZSpread || ["", undefined, null, 0, "0"].includes(ZSpread) ? "-" : ZSpread
    },
    {
      name: "OASSpread",
      selector: row => row.OASSpread,
      cell: row => !row.OASSpread || ["", undefined, null, 0, "0"].includes(OASSpread) ? "-" : OASSpread

    },
  ];





  useEffect(async() => {
    setLoading(true);
    try {
      let { data } = await httpService.get("watchlists/api/v1/getPlatformDefaultWatchlist?watchlist=df-default-ms-bond");
      setData(data);
    } catch (error) {
    }
    finally {
      setLoading(false);
    }
  }, [])


  
  return (
    <>
      <Paper sx={{ display: 'flex', flexDirection: 'column'}}>
        
        <DTable
          title="Bonds"
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          dense
          responsive
          fixedHeader
          fixedHeaderScrollHeight={`${(0.32 * document.documentElement.offsetHeight).toFixed(2)}px`}
        />
      </Paper>
    </>
  );


  // return (
  //   <>
  //     <div className="panel card panel-default mb-2 h-100">
  //       <div className="card-body px-2 py-2 h-100">
  //         <div className="row ">
  //           <div className="col-md-12">
  //             <div className="table-title">Bonds</div>
  //             <div className="pull-right table-panel-name">

  //               <div className="lastupdate">Last Update :</div>
  //               <span className="lastupdateHead">00:00:00</span>
  //               <DatePicker
  //                 selected={startDate}
  //                 onChange={(date) => setStartDate(date)}
  //               />
  //               <Button
  //                 variant="default"
  //                 className="color-white button-padding"
  //               >
  //                 <i className="fa fa-money"></i>Price
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="row bond-table h-90">
  //           <div className="col-md-12 h-100">
  //             <DataTable
  //               pagination={true}
  //               config={config}
  //               columnData={bondsTableData}
  //               data={data}
  //               itemPerPage={11}
  //               loading={loading}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default BondTable;
