import React from 'react'
import './index.scss'
import { store } from './store/store';
import Routes from './routes/routes';
import { Provider } from 'react-redux';
import { Provider as WebSocketProvider } from "./WebSocket";
import KeycloakProvider from './KeycloakProvider';
import { CssBaseline, Box, Zoom } from "@mui/material"
import CustomThemeProvider from "./CustomThemeProvider"
import { SnackbarProvider } from 'notistack';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


const App = () => {
    return (
        <KeycloakProvider>  
            <WebSocketProvider> 
                <Provider store={store}> 
                    <CustomThemeProvider> 
                        <Box sx={{ display: 'flex' }}> 
                            <CssBaseline /> 
                            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} TransitionComponent={Zoom}> 
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <Routes />
                            </LocalizationProvider>
                            </SnackbarProvider>
                        </Box>
                    </CustomThemeProvider>
                </Provider>
            </WebSocketProvider>
        </KeycloakProvider>
    )
}

export default App;
