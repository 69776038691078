import { Box } from "@mui/system";
import { useContext } from 'react';
import { ThemeModeContext } from "../../CustomThemeProvider";
const StatusCircle = ({ status }) => {
    const [themeMode, setThemeModel] = useContext(ThemeModeContext);


    const statusMap = {
        "save": "info",
        "submit": "success",
        "flag": "warning",
        "trade": "primary",
        "claim": "success",
    }

    return <Box  sx={{ bgcolor: `${statusMap[status]}.${themeMode}`, width: 8, height: 8, borderRadius: '50%' ,mx:"auto" }} />
    
}

export default StatusCircle;