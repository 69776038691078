import { Card, CardContent, Skeleton, CardActions, Box, CardMedia } from '@mui/material';


const ProfileCardSkeleton = () => {
    let sizing = {
        xs: 320,
        sm: 340,
        md: 360,
        lg: 380
    }
    return (

        <Card
            sx={{ width: "100%" }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                <Box>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Skeleton animation="wave" height={45} width={"100%"} />
                        <Skeleton animation="wave" height={30} width={60} />
                    </CardContent>
                    <CardActions>
                        <Skeleton animation="wave" height={30} width={60} />
                        <Skeleton animation="wave" height={30} width={60} />
                    </CardActions>
                </Box>
                <CardMedia>
                    <Skeleton width={151} height="100%" animation="wave" variant="rectangular" />
                </CardMedia>
            </Box>
        </Card>
        // <Card sx={{ width: "100%" }}>
        //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        //         <Box>
        //             <CardContent>
        //                 <Skeleton animation="wave" height={50} style={{ marginBottom: 6 }} />
        //                 <Skeleton animation="wave" height={45} width="80%" />
        //             </CardContent>
        //             <CardActions>
        //                 <Skeleton animation="wave" height={300} width={60} />
        //                 <Skeleton animation="wave" height={300} width={60} />
        //             </CardActions>
        //         </Box>
        //     </Box>

        // </Card>
    )
}

export default ProfileCardSkeleton
