import { useState, useEffect } from "react";
import {Paper} from '@mui/material';
import DTable from "../../../components/HigherOrderComponent/DTable";

import httpService from "../../../common/http.service";

const FXTable = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);


  useEffect(async () => {
    setLoading(true);
    try {
      let { data } = await httpService.get("watchlists/api/v1/getPlatformDefaultWatchlist?watchlist=df-default-ms-fx");
      setData(data);
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }, []);

 const columns = [
  {
    name: "Ticker",
    selector:row=>row.security,
  },
  {
    name: "%Change",
    selector:row=>row.ChangePercent,
  },
  {
    name: "Bid",
    selector:row=>row.Bid,


  },
  {
    name: "Ask",
    selector:row=>row.Ask,


  },
  {
    name: "Low",
    selector:row=>row.Low,


  },
  {
    name: "High",
    selector:row=>row.High,


  },
  {
    name: "Open",
    selector:row=>row.Open,


  },
  {
    name: "Prev",
    selector:row=>row.PrevClose,


  },
];

  return (
    <>
      <Paper sx={{  display: 'flex', flexDirection: 'column' }}>
        <DTable
          title="FX"
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          dense
          responsive
          fixedHeader
          fixedHeaderScrollHeight="50%"
        />
      </Paper>
    </>
  );
};

export default FXTable;
