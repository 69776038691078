import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';

import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


import DTable from '../../../components/HigherOrderComponent/DTable';
import { Grid, Paper } from '@mui/material';


export default function Strategy() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);


    const columns =[
        {
            name: "Strategy Id",
            selector:row=>row.strategy_id,
        },
        {
            name: "Strategy Name",
            selector:row=>row.strategy_name,
        },
        {
            name: "Strategy type",
            selector:row=>row.strategy_type,
        },
        {
            name: "Asset Class",
            selector:row=>row.asset_class,
        },
        {
            name: "Instrument Type",
            selector:row=>row.instrument_type,
        }, {
            name: "Description",
            selector:row=>row.description,
        },
    ]

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])


    const getRowData = () => {
        let d = localStorage.getItem("allStrategies");
        setData(d ? JSON.parse(d) : []);
    }


    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allStrategies", collectionId: "strategy_id", selectedId: selectedRow.strategy_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values, collectionName: "allStrategies", collectionId: "strategy_id", selectedId: selectedRow ? selectedRow.strategy_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />

                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedRows }) => {
        setSelectedRow(selectedRows[0] ?? null)
    }

    const fundsFormData = [
        {
            label: "Strategy Name:",
            type: "text",
            name: "strategy_name",
            validations: yup.string().required("please enter strategy name"),

        },
        {
            label: "Strategy Type:",
            type: "text",
            name: "strategy_type",
            validations: yup.string().required("please enter strategy type"),
        },
        {
            label: "Asset Class:",
            type: "text",
            name: "asset_class",
            validations: yup.string().required("please enter asset class"),
        },
        {
            label: "Instrument Type:",
            type: "text",
            name: "instrument_type",
            validations: yup.string().required("please enter instrument type"),
        },
        {
            label: "Description:",
            type: "text",
            name: "description",
            validations: yup.string().required("please enter description"),
        },
    ];

    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });

    

    return (
        <>
        <Grid container spacing={2}>
            <Grid item md={8}>
                <Paper>
                    <DTable
                        title="Strategy"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        dense
                        selectableRows
                        selectableRowsSingle
                        responsive
                        fixedHeader
                        onSelectedRowsChange={onRowSelect}
                        clearSelectedRows={toggledClearRows}
                        fixedHeaderScrollHeight="50%"
                    />
                </Paper>
            </Grid>
            <Grid item md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Strategy Details"}
                />
            </Grid>
        </Grid>
        </>
    )
}
