import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { ThemeModeContext } from "../../CustomThemeProvider";
import { useContext, useMemo } from 'react';
import TableSkeleton from '../common/TableSkeleton';
import { createTheme } from 'react-data-table-component';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';


const DTable = (props) => {
    const [themeMode, setThemeModel] = useContext(ThemeModeContext);
    const theme = useTheme();

    // useEffect(() => {}, [themeMode])


    createTheme(themeMode, {
        background: {
            default: theme.palette.mode == "dark" ? "#1e1e1e" : theme.palette.background.paper,
        },
    });




    return (
        <DataTable
            {...props}
            persistTableHead={true}
            progressComponent={
                <TableSkeleton
                    column_counts={props.columns.length}
                />
            }
            noDataComponent={
                <Box sx={{p:3}}>
                    <Typography variant="subtitle1">No Data</Typography>
                </Box>
            }
            theme={themeMode}
        />
    )
}

export default DTable

