import React, { useEffect, useState } from "react";
import Blotter from "./Blotter";
import OrderEntry from "./OrderEntry";
import OrderSummary from "./OrderSummary";
import { AppBar, Tab, TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTabs from "../../components/common/CustomTabs"
import { Box } from "@mui/system";

import CancelIcon from '@mui/icons-material/Cancel';




const Order = () => {

  const TABS = [
    {
      title: "Summary",
      component: <OrderSummary />,
    },
    {
      title: "Blotter",
      component: <Blotter />,
    },
    {
      title: "Order Entry",
      component: <OrderEntry />,
      toggle: false,
      isEditable: true,
      removable: false,
    },
  ];
  const [value, setValue] = useState(0);
  const [tabContent, setTabContent] = useState(TABS)

  const addTabHandler = () => {
    let new_tabs = [...tabContent, ...[{
      title: "Order Entry",
      component: <OrderEntry />,
      toggle: false,
      isEditable: true,
      removable: true
    }]];
    setTabContent(new_tabs);
    setValue(new_tabs.length - 1);
  }

  const removeTabHandler = (index) => {
    const newArray = tabContent.slice()
    newArray.splice(index, 1);
    setTabContent(newArray);
    setValue(newArray.length - 1);

  }

  const updateToggle = (i) => {
    const newArray = tabContent.slice()
    newArray[i].toggle = !newArray[i].toggle;
    setTabContent(newArray);
  }

  const updateTabLabel = (e, i) => {
    const newArray = tabContent.slice()
    newArray[i].title = e.target.value;
    setTabContent(newArray);
  }



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <CustomTabs
          value={value}
          onChange={handleChange}
        >
          {
            tabContent.map((content, key) => (
              <Tab
                disableRipple
                key={key}
                value={key}
                icon={
                  content.removable ? <CancelIcon onClick={e => removeTabHandler(key)} fontSize="small" /> : null
                }
                iconPosition="end"
                onDoubleClick={content.isEditable ? (e) => updateToggle(key) : null}
                label={
                  content.toggle ?
                    <TextField
                      hiddenLabel
                      variant="standard"
                      size="small"
                      value={content.title}
                      onChange={e => updateTabLabel(e, key)}
                      onKeyDown={e => ["Enter", "Escape"].includes(e.key) && updateToggle(key)}
                    />
                    : content.title
                }
              />
            ))
          }
          <IconButton onClick={addTabHandler}>
            <AddIcon color="primary"/>
          </IconButton>
        </CustomTabs>
      </AppBar>
      <Box>
        {tabContent[value]?.component}
      </Box>
    </>
  )
}

export default Order;
