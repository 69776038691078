import { useState , useMemo } from 'react';
import Fund from "./Fund/Fund";
import Strategy from "./Strategy/Strategy";
import SubStrategy from './SubStrategy/SubStrategy';
import Group from './Group/Group';
import Assignment from './Assignment/Assignment';
import PerformanceReport from './PerformanceReport/PerformanceReport';
import { AppBar, Paper, Tab } from '@mui/material';
import { Box } from '@mui/system';
import CustomTabs from '../../components/common/CustomTabs';
export default function FundAdmin() {
    const TABS = [
        {
            title: "Funds",
            component: <Fund />
        },
        {
            title: "Strategy",
            component: <Strategy />
        },

        {
            title: "Sub Strategy",
            component: <SubStrategy />
        },
        {
            title: "Group",
            component: <Group />
        },
        {
            title: "Assignment",
            component: <Assignment />
        },
        {
            title: "Performance Report",
            component: <PerformanceReport />
        }
    ]
    const tabContent = useMemo(()=>TABS);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <AppBar position="static" color="transparent" elevation={0}>
                <CustomTabs
                    value={value}
                    onChange={handleChange}>
                    {
                        tabContent.map((content, key) => (
                            <Tab
                                disableRipple
                                key={key}
                                value={key}
                                label={content.title}
                            />
                        ))
                    }
                </CustomTabs>

            </AppBar>
            <Box>
                {tabContent[value]?.component}
            </Box>
        </Box>

    )
}
