import React, { useState, useEffect } from "react";
// import TableFormGenerator from "./TableFormGenerator";
import { Box, Button } from "@mui/material";
import {
  // deleteFromLocal,
  saveToLocal,
} from "../../common/localStorage.service";
// import OrderEntryBox from "./OrderEntryBox";
// import OrderEnteryTable from "./OrderEnteryTable";
import TableFormGenerator from "./TableFormGenerator";

const OrderEntry = () => {
  const [orders, setOrders] = useState([]);

  const addDataHandler = () => {
    const temp_orders = [...orders, { key: orders.length + 1 }];
    setOrders(temp_orders);
  };

  useEffect(() => {
    getSavedOrders();
  }, []);

  const getSavedOrders = () => {
    let orders = JSON.parse(localStorage.getItem("orders")) ?? [];
    orders = orders?.filter((_) => _.status == "save");
    orders.length > 0
      ? setOrders(orders)
      : setOrders([{ key: orders.length + 1 }]);
  };

  const deleteOrder = (k) => {
    setOrders(orders.filter((e, key) => key != k));
  };

  const handleOnSubmit = (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    // check allocation sums is equal to 100
    let sum = values.subRow.reduce((a, b) => {
      return a + parseInt(b["allocation"]);
    }, 0);
    if (sum !== 100) {
      values.subRow.forEach((_, i) => {
        setFieldError(`subRow.${i}.allocation`, "error");
      });
      setSubmitting(false);
      return;
    }

    // check if combinaion os fund , strategy and sub-strategy is smilar ?

    let combination_map = {};
    let invalid_index = [];
    for (let i = 0; i < values.subRow.length; i++) {
      let combinaion = `${values.subRow[i].fund}-${values.subRow[i].strategy}-${values.subRow[i].substrategy}`;
      if (!combination_map[combinaion]) {
        combination_map[combinaion] = true;
        continue;
      }
      invalid_index.push(i);
    }

    if (invalid_index.length > 0) {
      invalid_index.forEach((i) => {
        setFieldError(`subRow.${i}.fund`, "error");
        setFieldError(`subRow.${i}.strategy`, "error");
        setFieldError(`subRow.${i}.substrategy`, "error");
      });
      setSubmitting(false);
      return;
    }

    setSubmitting(true);
    setTimeout(() => {
      values.orderDate = new Date();
      saveToLocal({
        values,
        collectionName: "orders",
        collectionId: "order_id",
        selectedId: values.order_id ?? null,
      });
      values.status == "submit" && resetForm();
      setSubmitting(false);
      getSavedOrders();
    }, 1000);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
        <Button variant="outlined" onClick={addDataHandler}>
          {" "}
          Add Order{" "}
        </Button>
        <Button variant="outlined"> Add Summary </Button>
      </Box>{" "}
      {
        // orders.map((_, i) => <OrderEntryBox key={i} handleOnSubmit={handleOnSubmit} deleteDataHandler={e=>deleteOrder(i)}/>)
        // orders.map((_, i) => <OrderEnteryTable key={i} handleOnSubmit={handleOnSubmit} deleteDataHandler={e=>deleteOrder(i)}/>)
        orders.map((_, i) => (
          <TableFormGenerator
            key={i}
            handleOnSubmit={handleOnSubmit}
            deleteDataHandler={(e) => deleteOrder(i)}
            formValues={_}
          />
        ))
      }{" "}
    </Box>
  );
};

export default OrderEntry;
