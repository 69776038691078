import React, { useState } from "react";
import BondTable from "./BondTable";
import EquityTable from "./EquityTable";
import FXTable from "./FXTable";
import { Grid, Container } from "@mui/material";
const MarketSummary = () => {
  const [show, setShow] = useState(false);
  const [tableToView, setTableToView] = useState(null);
  const setModal = (e) => {
    setTableToView(e);
    setShow(true);
  };
  return (
    <>
      <Grid container spacing={3} sx={{
        height: "92vh"
      }}>
        <Grid item xs={12} md={12} lg={12} sx={{height:"50%"}}>
          <BondTable />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{height:"50%"}} >
          <EquityTable />

        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{height:"50%"}}>
          <FXTable />
        </Grid>
      </Grid>
    </>
  );
};

export default MarketSummary;
