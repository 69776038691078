import React, { useState, useEffect, useRef, useContext } from "react";
import data from "../../../json/chart.json";
import { Box } from "@mui/system";
import Grid from '@mui/material/Grid'
import { Card, CardContent, Chip } from "@mui/material";
import { createChart, PriceScaleMode, LineStyle } from "lightweight-charts";
import { ThemeModeContext } from "../../../CustomThemeProvider";


const LineChart = ({ label, data }) => {
  const [themeMode, setThemeModel] = useContext(ThemeModeContext);

  let chatOptions = {
    rightPriceScale: {
      mode: PriceScaleMode.Normal,
      borderColor: "rgba(197, 203, 206, 0.4)",
    },
    timeScale: {
      borderColor: "rgba(197, 203, 206, 0.4)",
    },
    layout: {
      backgroundColor: "transparent",
      textColor: themeMode =="dark" ?  "#ffffff" : "#000",
    },
    grid: {
      vertLines: {
        color: "rgba(0, 0, 0, 0)",
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
  }
  let chartRef = useRef([]);
  useEffect(() => {

    const chart = createChart(chartRef.current, {
      ...chatOptions, label: label
    });
    data.forEach(element => {
      let areaSeries = chart.addAreaSeries();
      areaSeries.setData(element);
    });

    const ro = new ResizeObserver((entries) => {
      const cr = entries[0].contentRect;
      resize(cr.width, cr.height);
    });

    ro.observe(chartRef.current);

    if (chartRef.current.innerWidth) {
      window.addEventListener("resize", () => {
        resize(chartRef.current.innerWidth, chartRef.current.innerHeight);
      });
    }

    const resize = (width, height) => {
      chart.resize(width, height);
    };

    chart.timeScale().fitContent();
    return () => {
      chart.remove();
    }
  }, [chartRef,themeMode])
  return (
    <Box sx={{ height: "35vh" }} ref={el => chartRef.current = el}>
    </Box>
  )
}



const SentimentSummary = () => {
  const labelData = [
    { primaryLabel: "VIX", secondaryLabel: "VOL", chartSet1: data['vixSet1'].slice(Math.max(data['vixSet1'].length - 100, 0)), chartSet2: data['vixSet2'].slice(Math.max(data['vixSet1'].length - 100, 0)) },
    { primaryLabel: "TY", secondaryLabel: "RATES", chartSet1: data['tySet1'].slice(Math.max(data['tySet1'].length - 100, 0)), chartSet2: data['tySet2'].slice(Math.max(data['tySet1'].length - 100, 0)) },
    { primaryLabel: "S&P 500", secondaryLabel: "EQUITY", chartSet1: data['s&p500Set1'].slice(Math.max(data['s&p500Set1'].length - 100, 0)), chartSet2: data['s&p500Set2'].slice(Math.max(data['s&p500Set1'].length - 100, 0)) },
    { primaryLabel: "DXY", secondaryLabel: "FX", chartSet1: data['dxySet1'].slice(Math.max(data['dxySet1'].length - 100, 0)), chartSet2: data['dxySet2'].slice(Math.max(data['dxySet1'].length - 100, 0)) },
    { primaryLabel: "WTI", chartSet1: data['wt1Set1'].slice(Math.max(data['wt1Set1'].length - 100, 0)), chartSet2: data['wt1Set2'].slice(Math.max(data['wt1Set1'].length - 100, 0)) },
    { primaryLabel: "COPPER", chartSet1: data['cooperSet1'].slice(Math.max(data['cooperSet1'].length - 100, 0)), chartSet2: data['cooperSet2'].slice(Math.max(data['cooperSet1'].length - 100, 0)) },
  ];
  const [show, setShow] = useState(false);

  const onBadgeClick = (i) => {
    setShow(true)
  }



  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {labelData.map((e, i) => (
            <Grid item md={4} key={i}>
              <Card>
                <CardContent>
                  <Chip label={e.primaryLabel} variant="outlined" size="small" />
                  {e.secondaryLabel && <Chip label={e.secondaryLabel} variant="outlined" size="small" />}
                  <Box>
                    <LineChart data={[e.chartSet1, e.chartSet2]} label={e.primaryLabel} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
  // return (
  //     <>
  //     <div className="sentiment-summary row p-0" style={{height:"85vh"}}>
  //       {labelData.map((e, i) => (
  //         <div className={`col-md-4 ${(i === 0 || i=== 1 || i=== 2) ? 'mb-3' : ''}  ${(i === 1 || i === 4) ? 'p-0' : ''}`} key={i}>
  //           <Card className="w-100 h-100 bg-dark">
  //             <Card.Body className="cardBody p-0">
  //               <div className="badgeContainer d-flex align-items-center justify-content-between">
  //                 <Badge
  //                   onClick={() => onBadgeClick(i)}
  //                   bg="primary"
  //                   className="primaryLabel"
  //                 >
  //                   {e.primaryLabel}
  //                 </Badge>
  //                 <Badge bg="secondary" className="secondaryLabel">
  //                   {e.secondaryLabel}
  //                 </Badge>
  //               </div>
  //               <div>
  //               <LineChart  data={ {chartSet1:e.chartSet1 , chartSet2:e.chartSet2} } label={e.primaryLabel} chatOptions={chatOptions} />
  //               </div>
  //               <div className=" labelContainer  d-flex align-items-center justify-content-center">
  //                 <div className="d-flex align-items-center mr-3">
  //                   <div className="label-line-actual"></div>
  //                   <span> Actual</span>
  //                 </div>
  //                 <div className="d-flex align-items-center">
  //                   <div className="label-line-sentiment"></div>
  //                   <span> Sentiment</span>
  //                 </div>
  //               </div>
  //             </Card.Body>
  //           </Card>
  //         </div>
  //        ))} 
  //     </div>

  //   {show && (
  //     <Modal
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       show={show}
  //       onHide={() => {
  //         setShow(false);
  //       }}
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           Sentiment Chart
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //       <div className="row">
  //         <div className="col-md-12">
  //         <LineChart   data={ {chartSet1:areaSeriesData , chartSet2:extraSeriesData} }  chatOptions={chatOptions}/>
  //         </div>
  //       </div>
  //       </Modal.Body>
  //     </Modal>
  //   )}
  // </>
  // )
}

export default SentimentSummary