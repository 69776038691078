import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';

import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


import DTable from '../../../components/HigherOrderComponent/DTable';
import { Grid, Paper } from '@mui/material';
export default function Group() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, []);

    const getRowData = () => {
        let d = localStorage.getItem("allGroups");
        setData(d ? JSON.parse(d) : []);
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allGroups", collectionId: "group_id", selectedId: selectedRow.group_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values, collectionName: "allGroups", collectionId: "group_id", selectedId: selectedRow ? selectedRow.group_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedRows }) => {
        setSelectedRow(selectedRows[0] ?? null)
    }


    const columns = [
        {
            name: "Group ID",
            selector:row=>row.group_id,
        },
        {
            name: "Group Name",
            selector:row=>row.group_name,
        },
        {
            name: "Group type",
            selector:row=>row.group_type,
        },
        {
            name: "Description",
            selector:row=>row.description,
        },
    ];

    const fundsFormData = [
        {
            label: "Group Name",
            type: "text",
            name: "group_name",
            validations: yup.string().required("Please enter group name"),

        },
        {
            label: "Group Type:",
            type: "select",
            name: "group_type",
            validations: yup.string().required("Please select group type"),
            options: ["Fund", "Strategy", "Risk", "Admin"]
        },
        {
            label: "Description",
            type: "text-area",
            maxRows: 4,
            name: "description",
            validations: yup.string().required("Please enter description"),

        },
    ];

    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });




    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={8}>
                    <Paper>
                        <DTable
                            title="Group"
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            dense
                            selectableRows
                            selectableRowsSingle
                            responsive
                            fixedHeader
                            onSelectedRowsChange={onRowSelect}
                            clearSelectedRows={toggledClearRows}
                            fixedHeaderScrollHeight="50%"
                        />
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <FormGenerator
                        formData={fundsFormData}
                        initialData={selectedRow}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        schema={schema}
                        title={"Group"}
                    />
                </Grid>
            </Grid>
        </>
    )
}
