import React, { useState, useEffect, useRef } from "react";

import {
  Button,
  Link as MuiLink,
  Paper,
  Grid,
  Card,
  Box,
  CardMedia,
  Typography,
} from "@mui/material";
import { TextField, Stack, Divider, ButtonGroup } from "@mui/material";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import CropperModal from "../../../components/HigherOrderComponent/CropperModal";

export default function ProfileForm() {

  const [viewOnly, setViewOnly] = useState(false);
  const [img, setImage] = useState(null);


  // this example data for now
  const options = [
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
    { value: "option 3", label: "Option 3" },
  ];

  const profile = {
    username: "John21",
    first_name: "John",
    last_name: "Harrison",
    role: "Admin",
    region: "Dublin",
  };

  const fileInputRef = useRef(null);

  const fileInputhandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }else{
        setImage(null);
          return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    
  }

  //check if view
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    setViewOnly(query.get("view") === "1");
  }, []);

  const form_1 = [
    { name: "username", label: "Username", type: "text" },
    { name: "first_name", label: "First Name", type: "text" },
    { name: "last_name", label: "Last Name", type: "text" },
    { name: "role", label: "Role", type: "text" },
    { name: "region", label: "Region", type: "text" },
  ];
  const form_2 = [
    { name: "fund", label: "Funds", isMulti: true, type: "select2" },
    { name: "strategy", label: "Strategy", isMulti: true, type: "select2" },
    {
      name: "sub-strategy",
      label: "Sub-Strategy",
      isMulti: true,
      type: "select2",
    },
    { name: "group", label: "Group", isMulti: true, type: "select2" },
    { name: "assignment", label: "Assignment", isMulti: true, type: "select2" },
  ];

  return (<Paper sx={{ p: 4, m: 2 }}>
      <CropperModal img={img} />
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Card>
            <CardMedia
              component="img"
              sx={{ width: "100%" }}
              image="https://loremflickr.com/640/640"
            />
            <input
              style={{ display: "none" }}
              onChange={fileInputhandler}
              type="file"
              ref={fileInputRef}
              accept="image/*"
            />
            <Button variant="outlined" color="primary" fullWidth onClick={e=>{fileInputRef.current.click()}}>
              Edit
            </Button>
          </Card>
        </Grid>
        <Grid item md={8} sx={{ px: 2 }}>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item sm={12}>
                <Typography variant="h5">Basic Information</Typography>
              </Grid>
              {form_1.map((form_data, key) => {
                return (
                  <Grid item key={key} md={5} sm={10}>
                    <TextField
                      name={form_data.name}
                      label={form_data.label}
                      variant="standard"
                      fullWidth={true}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h5">Other Information</Typography>
              </Grid>
              {form_2.map((formdata, key) => {
                return (
                  <Grid item key={key} sm={12}>
                    {/* <MultipleSelectChip options={options} sx={{bgcolor:"red"}} /> */}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ButtonGroup
        sx={{ mt: 2 }}
        variant="outlined"
        color="primary"
        aria-label=""
      >
        <Button>Back</Button>
        <Button>Save</Button>
      </ButtonGroup>
    </Paper>
  );
}
