
import { Grid, Box, Typography, Button, Divider, Card, CardContent, CardActions ,CardHeader } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from "react"
// import styles from './Trade.module.scss';

let data = [
  { label: "Symbol:", value: "AAPL" },
  { label: "Company Name:", value: "CAP NAT" },
];

let buttonSet1 = ["FILL", 'BUY', 'SELL', 'QUOTE']
let buttonSet2 = ["LIMIT", 'SIZE', 'PRICE', 'T+1']

let list = [
  { label: 'PX:', value: '50' },
  { label: 'B/O:', value: '83' },
  { label: 'Last Px:', value: '80' },

  { label: 'Last Qty:', value: '1200' },

  { label: 'Daily H/L:', value: '100' },

  { label: '52 Wk H/L:', value: '100' },

]
const TradeEntry = ({ currentTradeOrder, updateTradeOrder,loading=false }) => {
  return (
    <>
      <Card>
        <CardHeader title="Details"></CardHeader>
        <CardContent>
          <Grid container spacing={0} sx={{ mb: 2 }}>
            {data.map((column, key) => (
              <Grid item md={6} key={key}>
                <Typography variant="subtitle2">
                  {column.label}
                </Typography>
                <Typography variant="subtitle1">
                  {column.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Divider />

          <Grid container
            spacing={1}
            sx={{ mt: 2 }}

          >

            {buttonSet1.map((column, key) => (
              <Grid key={key} md={3 } sx={{px:0.2}}>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  {column}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Grid container
            spacing={1}
            sx={{mt:0.5,mb:2}}
          >
            {buttonSet2.map((column, key) => (
              <Grid key={key} md={3} sx={{px:0.2}}> 
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  size="small"

                >
                  {column}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Divider />
          <Grid container spacing={0} sx={{ my: 2 }}>
            {list.map((column, key) => (
              <Grid key={key} md={6}>
                <Typography variant="subtitle2" display="block">
                  {column.label}
                </Typography>

                <Typography variant="subtitle1" display="block" gutterBottom>
                  {column.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            onClick={e => { updateTradeOrder?.() }}
            loading={loading}
            disabled={currentTradeOrder?.status !== "claim"}
            variant="contained"
            fullWidth
          >
            {currentTradeOrder?.status == "claim" ? "Trade" : "Processing"}
          </LoadingButton>
        </CardActions>
      </Card>
    </>
  )
}



export default TradeEntry
