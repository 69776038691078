import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import NTable from "../../components/HigherOrderComponent/NTable";
const ListTab = () => {
  const columns = [
    { name: "Sectory", selector: (r) => r.Sectory },
    { name: "Name", selector: (r) => r.Name },
    { name: "Issuer", selector: (r) => r.Issuer },
    { name: "ID", selector: (r) => r.ID },
    { name: "PX Last", selector: (r) => r.PXLast },
    { name: "Open", selector: (r) => r.Open },
    { name: "High", selector: (r) => r.High },
    { name: "Low", selector: (r) => r.Low },
    { name: "Close", selector: (r) => r.Close },
    { name: "Prev Close", selector: (r) => r.PrevClose },
    { name: "Volume", selector: (r) => r.Volume },
  ];

  const data = [
    {
      Sectory: "Financial Services",
      Industry: "Capital Markets",
      Name: "BAC",
      Issuer: "Bank of America Corporation (BAC)",
      ID: "BAC",
      PXLast: "47.20",
      Open: "46.51",
      High: "47.08",
      Low: "46.20",
      Close: "46.45",
      PrevClose: "47.08",
      Volume: "32,927,300",
    },
  ];
  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Sector</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NTable columns={columns} data={data} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Custom</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">No Data</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ListTab;
