import React, { useState } from 'react'

const Bond = props => {
  const [menu, setMenu] = useState(1)
  const [submenu, setSubmenu] = useState(1)
  const [startDate, setStartDate] = useState(new Date())
  const { modal } = props

  return (
    <>

    </>
  )
}

export default Bond
