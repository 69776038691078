import { Paper, Box, Grid, Divider, Typography } from "@mui/material"

const SecurityDetails = () => {
    const detailsPartOne = [
        { label: "ID/Ticker", value: 'MS' },
        { label: "PX_Last", value: '97.68' },
        { label: "+/- vs ytdy", value: '+0.32 (+0.33%)' },
        { label: "Timestamp", value: 'At close: 4:03PM EST' },
        { label: "name", value: 'Morgan Stanley (MS)' },
        { label: "sector", value: 'Financial Services' },
        { label: "industry", value: 'Capital Markets' },
        { label: "Market Cap", value: '175.278B' },
        { label: "P/E Ratio", value: '12.47' },
        { label: "Dividend", value: '2.80' },
        { label: "Exchange", value: 'NYSE' },
        { label: "PX_Range Today", value: '97.35 - 100.63' },
        { label: "MTD", value: '97.35-105.34' },
        { label: "YTD", value: '57.51 - 105.95' },
        { label: "Trading volume", value: '9,382,884' },

    ]

    const detailsPartTwo = [
        { label: "Revneue", value: '58.27B' },
        { label: 'EPS', value: '7.83' },
        { label: 'Operating Income', value: '14.24B' },
        { label: 'Operating Margin', value: '39.84%' },
        { label: 'Net Income', value: '14.24B' },
        { label: 'Net Profit Margin', value: '25.27%' },
    ];
    const detailsPartThree = [
        {label:"CEO" , value:'Mr. James Patrick Gorman'},
        {label:"Headquarter" , value:' New York, United States'},
        {label:"Website" , value:'http://www.morganstanley.com/'},
      ];
    return (
        <Box >
            <Paper sx={{py:1 ,height:500} } >
                <Grid container spacing={1} sx={{ mt: 1 }}>
                    {
                        detailsPartOne.map((_, key) => (
                            <Grid item key={key} md={4} >
                                <Typography align="center" variant="caption" gutterBottom component="div">{_.value}</Typography>
                                <Typography align="center" variant="caption" sx={{fontSize:"0.65rem"}} display="block" gutterBottom>({_.label})</Typography>
                            </Grid>
                        ))
                    }
                </Grid>
                <Divider />
                <Grid container spacing={1} sx={{ mt: 1 }}>
                    {
                        detailsPartTwo.map((_, key) => (
                            <Grid item key={key} md={4} >
                                <Typography align="center" variant="caption" gutterBottom component="div">{_.value}</Typography>
                                <Typography align="center" variant="caption" sx={{fontSize:"0.65rem"}} display="block" gutterBottom>({_.label})</Typography>
                            </Grid>
                        ))
                    }
                </Grid>
                <Divider />
                <Grid container spacing={1} sx={{ mt: 1 }}>
                    {
                        detailsPartThree.map((_, key) => (
                            <Grid item key={key} md={4} >
                                <Typography align="center" variant="caption" gutterBottom component="div">{_.value}</Typography>
                                <Typography align="center" variant="caption" sx={{fontSize:"0.65rem"}} display="block" gutterBottom>({_.label})</Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Paper>
        </Box>
    )
}

export default SecurityDetails
