import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { yellow, green, blue, grey ,red} from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { styled } from "@mui/system";
import { useContext } from 'react';
import { ThemeModeContext } from '../../CustomThemeProvider';
const ChipAvatar = styled(Avatar)(({ theme }) => ({
    bgcolor: grey[theme.palette.mode == "dark" ? 900 : 100]
}));

const configData = {
    "success": {
        dark: alpha(green[800], 0.5),
        light: alpha(green[800], 0.5)
    },
    "info": {
        dark: alpha(blue[800], 0.5),
        light: alpha(blue[800], 0.5)
    },
    "warning": {
        dark: alpha(yellow[800], 0.5),
        light: alpha(yellow[800], 0.5)
    },
    "error": {
        dark: alpha(red[800], 0.5),
        light: alpha(red[800], 0.5)
    }

}

const StatusTag = props => {
    const [themeMode, setThemeModel] = useContext(ThemeModeContext);

    const { quantity = null, color = "success", label = "", size = "medium" } = props;
    let bgcolor = configData[color][themeMode];

    const avatar = quantity ? (<ChipAvatar>{quantity}</ChipAvatar>) : null;

    return (
        <>
            <Chip avatar={avatar} variant="outlined" sx={{ bgcolor: bgcolor }} color={color} size={size} label={label} />

        </>
    )
}


export default StatusTag;