import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const NTable = (props) => {
    const { columns, data = [] } = props;
    return (
        <TableContainer>
            <Table size="small" >
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) =>  <TableCell key={index}>{column.name ?? ""}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&  data.map((row, index) => (
                        <TableRow
                            key={row, index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((column, key) => {
                                 let cellData = column.selector ? column.selector(row) : "" ;
                                return (<TableCell key={key}>{cellData}</TableCell>)
                            })}
                        </TableRow>
                    ))}
                    {
                        data.length ==0 && (
                            <TableCell colSpan={columns.length} align="center" sx={{py:3}}>No Data</TableCell>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default NTable
