import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import IMAGE from "../../static/images/DigitalAIr_logo.png";
import { Button } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';






const LoginWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
}));

const Logo = styled("img")(() => ({
  marginBottom: "1.5rem",
  marginLeft: "auto",
  marginRight: "auto",
}))


const Login = () => {
  const { keycloak } = useKeycloak();
  if (keycloak && keycloak.authenticated) {
    return <Redirect to="/dashboard" />;
  }

  const loginHandler = () => {
    keycloak.login()
  }
  return (
    <LoginWrapper>
      <Zoom in style={{ transitionDelay: '500ms' }}>
        <Card sx={{ width: "21rem", padding: "0.43rem", bgcolor: "rgb(0 0 0 / 50%)" }}>
          <CardContent>
            <Logo src={IMAGE} alt="" width="auto" height="60" />
            <Button variant="outlined" onClick={() => loginHandler()} fullWidth={true}  >LOGIN</Button>
          </CardContent>
        </Card>
      </Zoom >
    </LoginWrapper>

  );

}

export default Login
