import React from 'react';
import { Card, CardContent, Typography, Skeleton, CardActions, Button, CardMedia } from '@mui/material';


import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
export default function ProfileCard({ profile }) {

    return (
        <Card
            sx={{ width: "100%" }}
        >
            <Box sx={{ display: "flex" ,justifyContent:"space-between"}}>
                <Box>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            {`Name: ${profile.firstName} ${profile.lastName}`}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            Role: {profile.role}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="div">
                            Loaction: {profile.region}
                        </Typography>
                        

                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" color="info" component={Link} to={`/profile/${profile.id}`} >Edit</Button>
                        <Button size="small" color="primary" component={Link} to={`/profile/${profile.id}?view=1`} >view</Button>
                    </CardActions>
                </Box>
                <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={profile.photo}
                    loading="lazy"
                />
            </Box>


        </Card>

    )
}
