import React, { useState, useEffect, useCallback } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid, IconButton } from "@mui/material";
import { Card, CardContent, Typography, Skeleton } from "@mui/material";

import NTable from "./HigherOrderComponent/NTable";
import STable from "./HigherOrderComponent/STable";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import StatusTag from "./HigherOrderComponent/StatusTag";
import StatusCircle from "./common/StatusCircle";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { deleteFromLocal, saveToLocal } from "../common/localStorage.service";

import { useSnackbar } from "notistack";

const OrdersTable = ({ height = "41vh", loading, updateOrder, data = [] }) => {
  const [order, setOrder] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);

  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    let temp = data.slice();
    setOrder([
      ...temp.filter((_) => ["flag", "submit", "cancel"].includes(_.status)),
    ]);
  }, [data]);

  const { enqueueSnackbar } = useSnackbar();

  const handleRowSelection = (rows) => {
    console.log(rows);
  };

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const openMenuHandler = (e, row) => {
    console.log({ row });
    setAnchorEl(e.currentTarget);
    setSelectedOrder(row);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
    setSelectedOrder(null);
  };

  const claimOrder = () => {
    updateOrder(selectedOrder, { status: "claim" });
    enqueueSnackbar("Order Claimed successfully", {
      variant: "success",
    });
    setAnchorEl(null);
  };

  const modify = () => {
    updateOrder(selectedOrder, { status: "save" });
    enqueueSnackbar("Order Modified successfully", {
      variant: "success",
    });
    setAnchorEl(null);
  };

  const expandableComponent = useCallback((row) => {
    const subData = [
      { name: "Alloc (%)", selector: (r) => r.allocation },
      { name: "Fund", selector: (r) => r.fund },
      { name: "Strategy", selector: (r) => r.strategy },
      { name: "Sub-strategy", selector: (r) => r.substrategy },
      { name: "Notional", selector: (r) => r.notional },
      { name: "Price", selector: (r) => r.price },
      { name: "CCY", selector: (r) => r.ccy },
      { name: "MV", selector: (r) => r.mv },
      { name: "CCY(Base)", selector: (r) => r.ccyBase },
      { name: "MV(Base)", selector: (r) => r.mvBase },
      { name: "%Fund(pre)", selector: (r) => r.fundPre },
      { name: "%Fund(post)", selector: (r) => r.fundPost },
      { name: "Est Risk(%)", selector: (r) => r.estRisk },
      { name: "Est Risk(Base)", selector: (r) => r.estRiskBase },
      { name: "Cash%", selector: (r) => r.cash },
      { name: "Cash(Base)", selector: (r) => r.cashBase },
      { name: "Cash(t+1)", selector: (r) => r.casht1 },
      { name: "Cash(t+2)", selector: (r) => r.casht2 },
    ];
    return <NTable columns={subData} data={row?.subRow ?? []} />;
  }, []);

  const columns = [
    { id: "order_id", name: "Order Id", selector: (row) => row.order_id },
    // { id:"", name: "Instrument Type", selector: row => row.instrumentType },
    {
      id: "orderDate",
      name: "Order Date",
      selector: (row) => new Date(row.orderDate).toLocaleDateString(),
    },
    { id: "buySell", name: "Buy Sell", selector: (row) => row.buySell },
    {
      id: "tradeDate",
      name: "Trade Date",
      selector: (row) => new Date(row.tradeDate).toLocaleDateString(),
    },
    {
      id: "settleDate",
      name: "Settle Date",
      selector: (row) => new Date(row.settleDate).toLocaleDateString(),
    },
    { id: "ticker", name: "Ticker", selector: (row) => row.ticker },
    { id: "name", name: "Name", selector: (row) => row.name },
    {
      id: "status",
      name: "Status",
      align: "center",
      cell: (row) => <StatusCircle status={row?.status ?? null} />,
    },
    {
      name: "action",
      cell: (row) => (
        <IconButton onClick={(e) => openMenuHandler(e, row)}>
          <MoreHorizIcon />
        </IconButton>
      ),
      button: true,
    },
  ];

  return (
    <Card
      sx={{
        height: fullscreen ? "85vh" : height,
      }}
    >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={8}>
          <Typography variant="h5">
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                height={50}
                width={200}
              />
            ) : (
              "Order Summary"
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" width={100} height={45} />
              <Skeleton animation="wave" width={100} height={45} />
              <Skeleton animation="wave" width={100} height={45} />
              <Skeleton animation="wave" width={100} height={45} />
              <Skeleton animation="wave" width={45} height={45} />
            </>
          ) : (
            <>
              <StatusTag quantity={5} color="success" label="Submitted" />
              <StatusTag quantity={5} color="warning" label="Flagged" />
              <StatusTag quantity={5} color="info" label="Modified" />
              <StatusTag quantity={5} color="error" label="Cancelled" />
              <IconButton onClick={toggleFullscreen}>
                <OpenInFullIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
      <Menu
        dense
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenuHandler}
      >
        <MenuItem inset onClick={claimOrder}>
          Claim
        </MenuItem>
        <MenuItem inset>Cancel</MenuItem>
      </Menu>

      <CardContent>
        <STable
          columns={columns}
          data={order}
          rowSelection={true}
          expandable={true}
          loading={loading}
          expandableComponent={expandableComponent}
          height={fullscreen ? "100%" : height}
          onRowSelect={handleRowSelection}
        />
      </CardContent>
    </Card>
  );
};

export default OrdersTable;
