import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';

import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


import DTable from '../../../components/HigherOrderComponent/DTable';
import { Grid, Paper } from '@mui/material';


export default function Assignment() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])

    const fundsFormData = [
        {
            label: "Employee Name",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "employee_name",
            validations: yup.string().required("Please enter employee name"),
        },
        {
            label: "Employee Type:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "employee_type",
            validations: yup.string().required("Please enter employee type"),

        },
        {
            label: "Fund Name",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "fund_name",
            validations: yup.string().required("Please enter fund name"),

        },
        {
            label: "Strategy Name",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "strategy_name",
            validations: yup.string().required("Please enter strategy"),

        },
        {
            label: "Sub-Strategy Name",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "sub_strategy_name",
            validations: yup.string().required("Please enter sub-strategy"),

        },
        {
            label: "Risk Group",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "risk_group",
            validations: yup.string().required("Please enter risk group"),

        },

    ];


    const getRowData = () => {
        let d = localStorage.getItem("allAssignments");
        setData(d ? JSON.parse(d) : []);
    }


    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values, collectionName: "allAssignments", collectionId: "employee_id", selectedId: selectedRow ? selectedRow.employee_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
        }, 2000)
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allAssignments", collectionId: "employee_id", selectedId: selectedRow.employee_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />

                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedFlatRows, selectedRowIds }) => {
        setSelectedRow(selectedFlatRows[0] ? selectedFlatRows[0].original : null);
    }


    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });


    const columns = [
        {
            name: "Employee ID",
            selector:r=>r.employee_id,
        },
        {
            name: "Employee Name",
            selector:r=>r.employee_name,
        },
        {
            name: "Employee Type",
            selector:r=>r.employee_type,
        },
        {
            name: "Fund Name",
            selector:r=>r.fund_name,
        },
        {
            name: "Strategy name",
            selector:r=>r.strategy_name,
        },
        {
            name: "Sub-Strategy name",
            selector:r=>r.sub_strategy_name,
        },
        {
            name: "Risk Group",
            selector:r=>r.risk_group,
        },
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={8}>
                    <Paper>
                        <DTable
                            title="Assignment"
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            dense
                            selectableRows
                            selectableRowsSingle
                            responsive
                            fixedHeader
                            onSelectedRowsChange={onRowSelect}
                            clearSelectedRows={toggledClearRows}
                            fixedHeaderScrollHeight="50%"
                        />
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <FormGenerator
                        formData={fundsFormData}
                        initialData={selectedRow}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        schema={schema}
                        title={"Assignment Details"}
                    />
                </Grid>
            </Grid>
        </>
    )
}


