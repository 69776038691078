import { ThemeProvider, createTheme } from "@mui/material";
import { useState, useMemo, createContext, useEffect } from "react";





// global theme context

export const ThemeModeContext = createContext();
// todo
const getDesignTokens = (mode) => ({
    palette: {
        mode,
        //   ...(mode === 'light'
        //     ? lightTheme
        //     : darkTheme),
    },
});




const CustomThemeProvider = ({ children }) => {
    const [themeMode, setThemeMode] = useState('light');
    const theme = useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode]);

    useEffect(() => {
        setThemeMode(localStorage.getItem("theme") ?? themeMode);
    }, [])

    const changeThemeMode = (mode) => {
        setThemeMode(mode);
        localStorage.setItem("theme", mode);
    }




    return (
        <ThemeModeContext.Provider value={[themeMode, changeThemeMode]}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeModeContext.Provider>
    )
}

export default CustomThemeProvider
