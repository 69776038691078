import React, { useState, useEffect } from "react";
import TradeEntry from "./TradeEntry";
import Watchlist from "./Watchlist";
import Snapshot from "./Snapshot";
import { useHistory } from "react-router-dom";
import { Paper, AppBar, Tab, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomTabs from "../../components/common/CustomTabs";

import OrdersTable from "../../components/OrdersTable";
import TradesTable from "../../components/TradesTable";

import {
  deleteFromLocal,
  saveToLocal,
} from "../../common/localStorage.service";

const Trade = () => {
  const history = useHistory();

  const [orderData, setOrderData] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const [loading, setloading] = useState(false);

  const [TradeTabs, setTradeTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [subTab, setSubTab] = useState(0);

  const updateOrder = (order, updateValue) => {
    order = { ...order, ...updateValue };
    saveToLocal({
      values: order,
      collectionName: "orders",
      collectionId: "order_id",
      selectedId: order.order_id,
    });
  };

  const getTrade = () => {
    let data = localStorage.getItem("orders");
    data = data ? JSON.parse(data) : [];
    setTradeData([
      ...data.filter((_) => ["claim", "trade"].includes(_.status)),
    ]);
  };
  const getOrders = () => {
    let orders = localStorage.getItem("orders");
    orders = orders ? JSON.parse(orders) : [];
    setOrderData([...orders]);
  };

  useEffect(() => {
    getOrders();
    getTrade();
  }, []);

  useEffect(() => {
    let savedTrade = JSON.parse(localStorage.getItem("Trade")) ?? {
      trades: [],
      active: null,
    };
    const params = new URLSearchParams(history.location.search);
    let orderId = params.get("id");
    if (orderId) {
      setCurrentTab(orderId);
      savedTrade.trades = [...new Set([...savedTrade.trades, orderId])];
      savedTrade.active = orderId;
      setTradeTabs([...savedTrade.trades]);
    } else if (!!savedTrade?.trades?.length && savedTrade.active) {
      setCurrentTab(savedTrade.active);
      setTradeTabs([...savedTrade.trades]);
    }

    localStorage.setItem("Trade", JSON.stringify(savedTrade));
  }, [history.location]);
  const getcurrentOrder = (id) => {
    let data = orderData.find((o) => o.order_id == id);
    return data ?? {};
  };

  const Quotes = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <span>Quotes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Positions = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <span>Positions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const tabMenu = [
    {
      title: "Orders",
      component: <OrdersTable height={"35vh"} />,
    },
    {
      title: "Trades",
      component: <TradesTable height={"35vh"} />,
    },
    {
      title: "Quotes",
      component: <Quotes />,
    },
    {
      title: "Positions",
      component: <Positions />,
    },
  ];

  const subTabhandleChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const processTrade = (trade) => {
    setloading(true);
    setTimeout(() => {
      updateOrder(getcurrentOrder(currentTab) , { status:"trade"});
      setloading(false);
    }, 1200);
  };

  return (
    <Box>
      {currentTab ? (
        <>
          <AppBar position="static" color="transparent" elevation={1}>
            <CustomTabs value={currentTab}  onChange={(event, newValue)=>setCurrentTab(newValue)}>
              {TradeTabs &&
                TradeTabs.map((_, i) => (
                  <Tab key={i} label={`#${_}`} value={_} />
                ))}
            </CustomTabs>
          </AppBar>
          <Box sx={{ py: 1, px: 2 }}>
            <Grid container spacing={1.5}>
              <Grid item md={3}>
                <Watchlist />
              </Grid>
              <Grid item md={6}>
                <Snapshot />
              </Grid>
              <Grid item md={3}>
                <TradeEntry
                  currentTradeOrder={
                    orderData ? getcurrentOrder(currentTab) : null
                  }
                  updateTradeOrder={() => processTrade(currentTab)}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Paper
          sx={{
            height: "55vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1"> No Trades</Typography>
        </Paper>
      )}

      <Box>
        <AppBar position="static" color="transparent" elevation={1}>
          <CustomTabs value={subTab} onChange={subTabhandleChange}>
            {tabMenu.map((e, i) => (
              <Tab disableRipple key={i} value={i} label={e.title} />
            ))}
          </CustomTabs>
        </AppBar>
        <Paper>{tabMenu[subTab]?.component}</Paper>
      </Box>
    </Box>
  );
};
export default Trade;
