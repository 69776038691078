import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableBodySkeleton } from '../common/TableSkeleton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Typography } from '@mui/material';


const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    backgroundColor: theme.palette.mode == "dark" ? "#1e1e1e" : theme.palette.background.paper
}
));


const TableBodyCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: "2px 16px",
        marginTop: "10px",
        "&:last-child": {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderRadius: `${open ? "0 1em 0 0" : "0 1em 1em 0"}`,
        },
        "&:first-child": {
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRadius: `${open ? "1em 0 0 0" : "1em 0 0 1em"}`,
        }
    }
    ));


const ExpanderButtonCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        padding :"6px 0px",
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: "10px",
        borderRight: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRadius: `${open ? "0 1em 0 0" : "0 1em 1em 0"}`,
    }));


const BlankRow = styled(TableRow)(() => ({ border: 0 }));

const BlankCell = styled(TableCell)(() => ({ border: 0 }));


const ExpandableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        borderTop: `0`,
        borderleft: `1px solid ${theme.palette.divider}`,

        padding: 0,
        ...(!open && {
            borderBottom: `0`,
            padding: '0',
        }),
        ...(open && {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRadius: "0 0 1em 1em"
        })
    }));

const Table_ = styled(Table)(({ theme }) => ({
    borderCollapse: "separate",
}));


const TableRow_ = styled(TableRow)(({ theme }) => ({
    ".Mui-selected": {
        backgroundColor: theme.palette.grey[theme.mode == "dark" ? 800 : 300]
    },
    "&:hover": {
        backgroundColor: theme.palette.grey[theme.mode == "dark" ? 900 : 400]
    }
}));


const Row = (props) => {
    const { row, rowSelection, expandable, columns, expandableComponent, selected, onselect } = props;
    const [open, setOpen] = useState(false);



    return (
        <>
            <TableRow_ selected={selected || open} hover>
                {/* selector */}
                {
                    rowSelection &&
                    <TableBodyCell open={open}>
                        <Checkbox
                            size="small"
                            checked={selected}
                            onClick={e => onselect(row)}
                            sx={{ p: 0 }}
                        />
                    </TableBodyCell>
                }
                {/* data */}

                {
                    columns.map((column, key) => {
                        let selectedValue = column.selector ? column.selector(row) : null;
                        let cellData = column.cell ? column.cell(row) : selectedValue;
                        return (<TableBodyCell align={column.align ?? "left"} key={key}>{cellData}</TableBodyCell>)
                    })
                }
                {/* expandar */}
                {
                    expandable &&
                    <ExpanderButtonCell open={open} align="center">
                        <IconButton size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </ExpanderButtonCell>
                }

            </TableRow_>
            <TableRow>
                <ExpandableCell open={open} colSpan={columns.length + (expandable ? 1 : 0) + (rowSelection ? 1 : 0)}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && expandableComponent?.(row)}
                    </Collapse>
                </ExpandableCell>
            </TableRow>
            <BlankRow>
                <BlankCell colSpan={columns.length + (expandable ? 1 : 0) + (rowSelection ? 1 : 0)}></BlankCell>
            </BlankRow>

        </>
    )
}




const STable = (props) => {
    const { columns, data = [], rowSelection = false, expandable = true, expandableComponent = null, loading, height } = props;
    const { onRowSelect } = props;
    const [selected, setSelected] = useState([]);
    const onSelectAllClick = (e) => {
        if (e.target.checked) {
            setSelected(data);
            return;
        }
        setSelected([]);
    }

    useEffect(() => {
        onRowSelect?.(selected);
    }, [selected])

    const isSelected = (row) => selected.indexOf(row) !== -1;


    const selectHandler = (row) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(row);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }

    return (
        <TableContainer sx={{ height }}>
            <Table_ size="small" stickyHeader >
                <TableHead >
                    <TableRow>
                        {
                            rowSelection &&
                            <TableHeaderCell>
                                <Checkbox
                                    disabled={loading}
                                    onChange={onSelectAllClick}
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    size="small"
                                    sx={{ p: 0 }} />
                            </TableHeaderCell>
                        }
                        {columns.map((column, index) => (
                            <TableHeaderCell size="small" key={index} align={column.align ?? "left"}>
                                {/* <TableSortLabel> */}

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent:column.align ?? "left",
                                    flexWrap: 'wrap',
                                }}>
                                    {column.name ?? ""}
                                    {column.id &&
                                        <IconButton size="small">
                                            <FilterAltIcon fontSize="inherit" />
                                        </IconButton>

                                    }
                                </div>
                                {/* </TableSortLabel> */}
                            </TableHeaderCell>
                        ))}

                        {expandable && <TableHeaderCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        loading && <TableBodySkeleton size="medium" column_counts={columns.length + (expandable ? 1 : 0) + (rowSelection ? 1 : 0)} />
                    }
                    {!loading && data.length > 0 && data.map((row, index) => {
                        let isItemSelected = isSelected(row)
                        return (
                            <Row
                                key={index}
                                row={row}
                                columns={columns}
                                expandableComponent={expandableComponent}
                                rowSelection={rowSelection}
                                expandable={expandable}
                                selected={isItemSelected}
                                onselect={selectHandler}
                            />
                        )
                    }
                    )}
                    {
                        data.length === 0 &&
                        (
                            <TableRow>
                                <TableCell align="center" sx={{ border: 0 }} colSpan={columns.length + (expandable ? 1 : 0) + (rowSelection ? 1 : 0)}>
                                    No Data
                                </TableCell>
                            </TableRow>

                        )
                    }
                </TableBody>
            </Table_>
        </TableContainer>
    )
}

export default STable
