import React, { useState, useEffect, useCallback } from "react";

import {
  Collapse,
  Paper,
  TableCell,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Table,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import NTable from "../../components/HigherOrderComponent/NTable";

const Cell = styled(TableCell)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  "&:last-of-type": {
    borderLeft: 0,
  },
  "&:first-of-type": {
    borderRight: 0,
  },
}));

const SingleCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

const ExpandableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(!open && {
    borderBottom: `0`,
    padding: "0",
  }),
  ...(open && {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  }),
}));
const ExpandedTable = (row) => {
  const subColumn = [
    {
      name: "Ticker",
      selector: (r) => r.Ticker,
    },
    {
      name: "Pe Ration",
      selector: (r) => r.PeRation,
    },
    {
      name: "Market Cap",
      selector: (r) => r.MarketCap,
    },
    {
      name: "Volumn",
      selector: (r) => r.Volumn,
    },
  ];

  const subData = [
    {
      Ticker: "10.00",
      PeRation: "20.00",
      MarketCap: "50.4",
      Volumn: "60.5",
    },
    {
      Ticker: "55.2",
      PeRation: "40.55 ",
      MarketCap: "70.23",
      Volumn: "10.00",
    },
    {
      Ticker: "21.55",
      PeRation: "0.02",
      MarketCap: "55.69",
      Volumn: "12.3",
    },
    {
      Ticker: "10.5",
      PeRation: "55.3",
      MarketCap: "60.2",
      Volumn: "0.02",
    },
  ];

  return <NTable columns={subColumn} data={subData ?? []} />;
};

const Row = (props) => {
  const { row, columns, expandableComponent } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow selected={open} hover>
        <Cell>
          <IconButton aria-label="" onClick={() => setOpen(!open)} size="small">
            {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Cell>
        {columns.map((column, key) => {
          if (column.selector) {
            let selectedValue = column.selector ? column.selector(row) : null;
            let cellData = column.cell ? column.cell(row) : selectedValue;
            return (
              <Cell align={column.align ?? "left"} key={key}>
                {cellData}
              </Cell>
            );
          }
        })}
      </TableRow>
      <TableRow>
        <ExpandableCell open={open} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && <ExpandedTable row={row} />}
          </Collapse>
        </ExpandableCell>
      </TableRow>
    </>
  );
};

const Portfolio = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const data2 = [
    {
      group1: "G1",
      group2: "G2",
      ticker: "MS",
      month1: "53.20",
      lastPrice: "10.2",
      lnclone: "55.00",
      lastYear: "0.005",
      postions_overnight: "1",
      postions_trading: "10,1000",
      postions_total: "100000",
      pl_overnight: "500",
      pl_trading: "300",
      pl_total: "20",
    },
  ];

  const header1 = [
    { align: "center", colSpan: 1, title: "" },
    { align: "center", colSpan: 3, title: "" },
    { align: "center", colSpan: 4, title: "Secuity" },
    { align: "center", colSpan: 3, title: "" },
    { align: "center", colSpan: 3, title: "" },
  ];

  const header2 = [
    { align: "center", colSpan: 1, title: "" },
    { align: "center", colSpan: 3, title: "" },
    { align: "center", colSpan: 1, title: "Chart" },
    { align: "center", colSpan: 3, title: "Price" },
    { align: "center", colSpan: 3, title: "Postion" },
    { align: "center", colSpan: 3, title: "P&L" },
  ];

  const columns = [
    { align: "center", colSpan: 1, title: "" },
    {
      align: "center",
      colSpan: 1,
      title: "Group 1",
      selector: (r) => r.group1,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Group 2",
      selector: (r) => r.group2,
    },
    { align: "center", colSpan: 1, title: "Ticker", selector: (r) => r.ticker },
    {
      align: "center",
      colSpan: 1,
      title: "1 Month",
      selector: (r) => r.month1,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Last Price",
      selector: (r) => r.lastPrice,
    },
    { align: "center", colSpan: 1, title: "L / N", selector: (r) => r.lnclone },
    {
      align: "center",
      colSpan: 1,
      title: "Last Year",
      selector: (r) => r.lastYear,
    },
    {
      align: "center",
      colSpan: 1,
      title: "OrverNight",
      selector: (r) => r.postions_overnight,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Trading",
      selector: (r) => r.postions_trading,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Total",
      selector: (r) => r.postions_total,
    },
    {
      align: "center",
      colSpan: 1,
      title: "OrverNight",
      selector: (r) => r.pl_overnight,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Trading",
      selector: (r) => r.pl_trading,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Total",
      selector: (r) => r.pl_total,
    },
  ];

  return (
    <Card>
      <CardHeader title="Portfolio" sx={{ mb: 0, mt: "0.5em", p: " 0 1em" }} />
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Autocomplete
            sx={{ width: 300, mr: 3 }}
            getOptionLabel={(option) => option.name}
            options={[{ name: "Portfolio 1" }, { name: "Portfolio 2" }]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select PortFolio"
                fullWidth={true}
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: 300, mr: 3 }}
            getOptionLabel={(option) => option.name}
            options={[{ name: "Fund 1" }, { name: "Fund 2" }]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Funds"
                fullWidth={true}
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Box>
      </CardContent>
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {header1.map((_, key) => (
                  <Cell
                    align={_.align ?? "left"}
                    colSpan={_.colSpan ?? 1}
                    key={key}
                  >
                    {_.title}
                  </Cell>
                ))}
              </TableRow>
              <TableRow>
                {header2.map((_, key) => (
                  <Cell
                    align={_.align ?? "left"}
                    colSpan={_.colSpan ?? 1}
                    key={key}
                  >
                    {_.title}
                  </Cell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((_, key) => (
                  <Cell
                    align={_.align ?? "left"}
                    colSpan={_.colSpan ?? 1}
                    key={key}
                  >
                    {_.title}
                  </Cell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // !loading && !!data2.length ?
                //   (
                data2.map((row, key) => (
                  <Row key={key} row={row} columns={columns} />
                ))

                // ) : (
                //   <TableRow>

                //     <NoDataCell colSpan={15}>
                //       <Box
                //         sx={{
                //           py: 4,
                //           display: "flex",
                //           justifyContent: "center",
                //           alignItems: "center",
                //         }}
                //       >
                //         No Data Found
                //       </Box>
                //     </SingleCell>
                //   </TableRow>

                // )
              }
              <TableRow>
                <Cell>Total</Cell>
                {[...Array(columns.length - 1).keys()].map((i,key) => (
                  <Cell key={key}></Cell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default Portfolio;
