import { Paper, Grid, Button, Box, TextField, IconButton, Typography, Divider, Card, CardContent, CardHeader } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import DTable from "../../components/HigherOrderComponent/DTable";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import AddBoxIcon from '@mui/icons-material/AddBox';
const Watchlist = () => {
  const data = [
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "28.25",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "239.00",
      bid: "35.50",
      ask: "-",
    },


    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "15.85",
      bid: "35.50",
      ask: "70.00",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "0.11",
      bid: "35.50",
      ask: "70.00",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "0.09",
      bid: "35.50",
      ask: "36.00",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "-",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "-",
    },
    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "-",
    },



    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "-",
    },

    {
      symbol: "ABSA",
      exchange: "NSE",
      ccy: "KES",
      pre_close: "27.20",
      last_price: "28.25",
      bid: "35.50",
      ask: "-",
    },




  ];
  const columns = [
    {
      name: "Symbol",
      selector: row => row.symbol,

    },
    {
      name: "Exchange",
      selector: row => row.exchange,

    },
    {
      name: "CCY",
      selector: row => row.ccy,

    },
    {
      name: "Px Prev",
      selector: row => row.pre_close,

    },
    {
      name: "Px Last",
      selector: row => row.last_price,

      cell: (row) => (
        <span className={row.last_price == '28.25' ? 'text-success' : null || (row.last_price == '36.00' || row.last_price == '15.85') ? 'text-danger' : null}>{row.last_price}</span>
      ),
    },
    {
      name: "Bid",
      selector: row => row.bid,

    },
    {
      name: "Ask",
      selector: row => row.ask,

      cell: (row) => (
        <span className={row.ask == '70.00' && 'text-success'}>{row.ask} {row.ask == '70.00' ? <i className="fa fa-arrow-up"> </i> : ''}</span>
      ),
    },
  ];

  return (
    <>
      <Card sx={{maxHeight:"100%"}}>
        <CardHeader title="Watchlist"></CardHeader>
        <CardContent>

          <Grid container spacing={2}>
            <Grid item md={8}>
              <Autocomplete
                disableClearable
                getOptionLabel={option => option.ticker}
                options={[{ name: "Morgan Stanley", ticker: "MS", price: 150 }, { name: "Apple", ticker: "AAPL", price: 540 }]}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <strong>{option.ticker}</strong>(<small>{option.name}</small>)
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Ticker"
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <IconButton color="success"><AddBoxIcon /></IconButton>
              <IconButton color="error" ><DeleteForeverIcon /></IconButton>
            </Grid>
          </Grid>
          <DTable
            columns={columns}
            data={data}
            dense
            responsive
            fixedHeader
            highlightOnHover
          />
        </CardContent>
      </Card>
    </>
  )
};

export default Watchlist;
