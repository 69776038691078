import React, { useState, useEffect } from 'react';
import { Grid, Button, Box } from '@mui/material';

import ProfileCard from './ProfileCard';
import { Link} from 'react-router-dom';
import ProfileCardSkeleton from '../../components/common/ProfileCardSkeleton';

export default function Profile() {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const data = [
        { id: 1, firstName: "Mike", lastName: "Watson", role: "Test", region: "Zurik", photo: "https://loremflickr.com/640/640" },
        { id: 2, firstName: "Alferd", lastName: "Roy", role: "Test", region: "Oslo", photo: "https://loremflickr.com/640/640" },
        { id: 3, firstName: "Jose", lastName: "Franko", role: "Test", region: "London", photo: "https://loremflickr.com/640/640" },
        { id: 4, firstName: "Alferd", lastName: "Roy", role: "Test", region: "Oslo", photo: "https://loremflickr.com/640/640" },

    ];
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setProfiles(data);
            setLoading(false);
        }, 1500)
    }, [])

    return (
        <>
            <Box sx={{ pt: 2, px: 2 }}>
                <Link to="/profile/add">
                    <Button  variant="outlined" sx={{mb:2}}>
                        Add New
                    </Button>
                </Link>

                {
                    !loading ? (
                        <Grid container spacing={2} >
                            {
                                profiles.map((profile, i) => (
                                    <Grid xs={3} md={3} key={i} item>
                                        <ProfileCard profile={profile} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    ) : (
                        <Grid container spacing={2} >

                            {
                                [...Array(12).keys()].map((i) => (
                                    <Grid xs={3} md={3} key={i} item>

                                        <ProfileCardSkeleton key={i} />
                                    </Grid>
                                ))
                            }
                        </Grid>)
                }

            </Box>

        </>
    )
}
