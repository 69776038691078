import { useState, useEffect, useCallback } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid, IconButton } from "@mui/material";
import STable from "./HigherOrderComponent/STable";
import { Card, CardContent, Typography, Skeleton } from "@mui/material";

import NTable from "./HigherOrderComponent/NTable";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import StatusTag from "./HigherOrderComponent/StatusTag";
import StatusCircle from "./common/StatusCircle";
import { deleteFromLocal, saveToLocal } from "../common/localStorage.service";
import { useHistory } from "react-router-dom";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useSnackbar } from "notistack";

const TradesTable = ({ height = "41vh", loading, updateOrder, data = [] }) => {
  const [trades, setTradesData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);

  const menuOpen = Boolean(anchorEl);
  const history = useHistory();

  useEffect(() => {
      let temp=data.slice();
      setTradesData([ ...temp.filter((_) => ["processing", "trade", "claim"].includes(_.status)),
    ]);
  }, [data]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleRowSelection = (selectedRows) => {};


  const openMenuHandler = (e, row) => {
    setAnchorEl(e.currentTarget);
    setSelectedTrade(row);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
    setSelectedTrade(null);
  };

  const navigateToTrade = () => {
    history.push({
      pathname: "/trade",
      search: `id=${selectedTrade.order_id}`,
    });
  };

  const flagOrder = () => {
    updateOrder(selectedTrade, { status: "flag" });
    enqueueSnackbar("Order Flagged successfully", {
      variant: "success",
    });
  };

  const columns = [
    { id: "order_id", name: "Order Id", selector: (row) => row.order_id },
    // { id:"", name: "Instrument Type", selector: row => row.instrumentType },
    {
      id: "orderDate",
      name: "Order Date",
      selector: (row) => new Date(row.orderDate).toLocaleDateString(),
    },
    { id: "buySell", name: "Buy Sell", selector: (row) => row.buySell },
    {
      id: "tradeDate",
      name: "Trade Date",
      selector: (row) => new Date(row.tradeDate).toLocaleDateString(),
    },
    {
      id: "settleDate",
      name: "Settle Date",
      selector: (row) => new Date(row.settleDate).toLocaleDateString(),
    },
    { id: "ticker", name: "Ticker", selector: (row) => row.ticker },
    { id: "name", name: "Name", selector: (row) => row.name },
    {
      id: "status",
      name: "Status",
      align: "center",
      cell: (row) => <StatusCircle status={row?.status ?? null} />,
    },
    {
      name: "action",
      cell: (row) => (
        <IconButton onClick={(e) => openMenuHandler(e, row)}>
          <MoreHorizIcon />
        </IconButton>
      ),
      button: true,
    },
  ];

  const expandableComponent = useCallback((row) => {
    const subData = [
      { name: "Alloc (%)", selector: (r) => r.allocation },
      { name: "Fund", selector: (r) => r.fund },
      { name: "Strategy", selector: (r) => r.strategy },
      { name: "Sub-strategy", selector: (r) => r.substrategy },
      { name: "Notional", selector: (r) => r.notional },
      { name: "Price", selector: (r) => r.price },
      { name: "CCY", selector: (r) => r.ccy },
      { name: "MV", selector: (r) => r.mv },
      { name: "CCY(Base)", selector: (r) => r.ccyBase },
      { name: "MV(Base)", selector: (r) => r.mvBase },
      { name: "%Fund(pre)", selector: (r) => r.fundPre },
      { name: "%Fund(post)", selector: (r) => r.fundPost },
      { name: "Est Risk(%)", selector: (r) => r.estRisk },
      { name: "Est Risk(Base)", selector: (r) => r.estRiskBase },
      { name: "Cash%", selector: (r) => r.cash },
      { name: "Cash(Base)", selector: (r) => r.cashBase },
      { name: "Cash(t+1)", selector: (r) => r.casht1 },
      { name: "Cash(t+2)", selector: (r) => r.casht2 },
    ];
    return <NTable columns={subData} data={row?.subRow ?? []} />;
  }, []);

  return (
    <Card
      sx={{
        height: fullscreen ? "100%" : height,
      }}
    >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={8}>
          <Typography variant="h5">
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                height={50}
                width={200}
              />
            ) : (
              "Trade Summary"
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" width={120} height={45} />
              <Skeleton animation="wave" width={120} height={45} />
              <Skeleton animation="wave" width={120} height={45} />
              <Skeleton animation="wave" width={45} height={45} />
            </>
          ) : (
            <>
              <StatusTag quantity={5} color="success" label="Processing" />
              <StatusTag quantity={5} color="info" label="Executed" />
              <StatusTag quantity={5} color="warning" label="Settled" />
              <IconButton onClick={()=>setFullscreen(!fullscreen)}>
                <OpenInFullIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>

      <CardContent>
        <Menu
          dense
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={closeMenuHandler}
        >
          <MenuItem inset onClick={navigateToTrade}>
            Trade
          </MenuItem>
          <MenuItem inset onClick={flagOrder}>
            Flag
          </MenuItem>
        </Menu>
        <STable
          columns={columns}
          data={trades}
          rowSelection={true}
          expandable={true}
          loading={loading}
          expandableComponent={expandableComponent}
          height={fullscreen ? "100%" : height}
        />
      </CardContent>
    </Card>
  );
};

export default TradesTable;
