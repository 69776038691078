import axios from 'axios';
import AuthService from '../services/auth';



const API_URL = process.env.REACT_APP_FIN_TECH_API;
class HttpService {

  get(url, params = {}) {
    return call('GET', url, params)
  }

  post(url, params, payload = {}) {
    return call('POST', url, params, payload)
  }

  delete(url, params = {}) {
    return call('DELETE', url, params)
  }
  put(url, params = {}, payload = {}) {
    return call('PUT', url, params, payload)
  }
}

function call(method, URL, params, payload = {}) {
  const opts = {
    method,
    url: API_URL + URL
  }
  if (params) opts.params = params
  if (payload) opts.data = payload
  return axios(opts)
}

axios.interceptors.request.use(
  function (config) {
    const token = AuthService.getToken()
    if (token) config.headers["Authorization"] = `Bearer ${token}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
);



export default new HttpService()
