
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export const TableBodySkeleton = ({ row_counts = 8, column_counts = 4 }) => {
    return (
        <>
            {
                [...Array(row_counts).keys()].map((k, j) => (
                    <TableRow key={j}>
                        <TableCell>
                            <Skeleton variant="text" animation="wave" />
                        </TableCell>
                        <TableCell colSpan={column_counts - 1}>
                            <Skeleton variant="text" animation="wave" />
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}


const TableSkeleton = (props) => {
    const { height, row_counts = 8, column_counts = 4  ,size = "small" } = props
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: height ?? "50%" }} size={size}>
                    <TableBody>
                        <TableBodySkeleton {...props} />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


export default TableSkeleton;