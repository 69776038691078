import { Route, Switch } from 'react-router-dom'
import Header from './Header'
import Dashboard from '../../containers/Dashboard/Dashboard'
import Bond from '../../containers/Bond/Bond'
import Portfolio from '../../containers/Portfolio/Portfolio'
import FundAdmin from '../../containers/FundAdmin/FundAdmin'
import Security from '../../containers/Security/Security'
import Profile from '../../containers/Profile/Profile'
import ProfileForm from '../../containers/Profile/ProfileForm/ProfileForm'
import Order from '../../containers/Order/Order'
import Trade from '../../containers/Trade/Trade'


import { Box, Toolbar } from "@mui/material"

const Layout = () => {


  const routes = [
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    { path: '/fund-admin', name: 'fund-admin', component: FundAdmin },
    { path: '/profile', name: 'profile', component: Profile },
    { path: '/profile/add', name: '/profile/add', component: ProfileForm, exact: false },
    { path: '/profile/:id', name: '/profile/:id', component: ProfileForm, exact: false },
    { path: '/TradeCapture/FixedIncomeBond', name: '/TradeCapture/FixedIncomeBond', component: Bond },
    { path: '/portfolio', name: 'portfolio', component: Portfolio },
    { path: '/security', name: 'security', component: Security },
    { path: '/order', name: 'order', component: Order },
    { path: '/trade', name: 'trade', component: Trade }
  ]

  return (
    <>


      <Header action={data => setOpenSidebar(data)} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          width: "100vw",
          overflow: 'auto',
        }}
      >
        <Toolbar variant="dense" color="transparent" />
        <Switch>
          {routes.map((route, idx) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact ?? true}
                name={route.name}
                render={props =>
                  <route.component {...props} />
                } />
            )
          })}
        </Switch>
      </Box>

    </>
  )
}

export default Layout
