import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "../../components/Confirmation";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  deleteFromLocal,
  saveToLocal,
} from "../../common/localStorage.service";
import OrdersTable from "../../components/OrdersTable";
import TradesTable from "../../components/TradesTable";
import DTable from "../../components/HigherOrderComponent/DTable";
import { Box } from "@mui/system";
import { Divider, Stack } from "@mui/material";

const Blotter = () => {
  const [orders, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllorders();
  }, []);

  const getAllorders = () => {
    setLoading(true);
    let data = localStorage.getItem("orders");
    data = data ? JSON.parse(data) : [];
    setOrder(data);
    setLoading(false);
  };
  const updateOrder = (order, updateValue) => {
    order = { ...order, ...updateValue };
    saveToLocal({
      values: order,
      collectionName: "orders",
      collectionId: "order_id",
      selectedId: order.order_id,
    });
    getAllorders();
  };

  return (
    <Box sx={{ height: "100%", p: 3 }}>
      <Stack spacing={2}>
        <OrdersTable
          data={orders}
          loading={loading}
          updateOrder={updateOrder}
        />
        <TradesTable
          data={orders}
          loading={loading}
          updateOrder={updateOrder}
        />
      </Stack>
    </Box>
  );
};

export default Blotter;
