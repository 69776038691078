import { useState, useEffect } from "react";
import {Paper} from '@mui/material';
import httpService from "../../../common/http.service";
import DTable from "../../../components/HigherOrderComponent/DTable";

const EquityTable = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);


  useEffect(async () => {
    setLoading(true);
    try {
      let { data } = await httpService.get("watchlists/api/v1/getPlatformDefaultWatchlist?watchlist=df-default-ms-equity");
      setData(data);
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }, []);

  const columns = [
      {
        name: "Ticker",
        selector:row=> row.security,
        width: 30,
      },
      {
        name: "Name",
        selector:row=> row.companyName,        
      },
      {
        name: "%Change",
        selector:row=> row.change,        
      },
      {
        name: "Price",
        selector:row => row.latestPrice,        
      },
      {
        name: "Bid",
        selector:row=> row.bid,        
    
      },
      {
        name: "Ask",
        selector:row=> row.ask,        
      },
    
      {
        name: "Low",
        selector:row=> row.low,        
      },
      {
        name: "High",
        selector:row=> row.high,        
      },
      {
        name: "Open",
        selector:row=> row.open,        
      },
      {
        name: "Prev Close",
        selector:row=> row.previousClose
      },
  ]




  return (
    <>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <DTable
          title="Equity"
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          dense
          responsive
          fixedHeader
          fixedHeaderScrollHeight="50%"
        />
      </Paper>
    </>
  );
};

export default EquityTable;
