import React from "react";
import { Box } from "@mui/material";
import NTable from "../../components/HigherOrderComponent/NTable";
const PortfolioTab = () => {
  const columns = [
    { name: "Sectory", selector: (r) => r.Sectory },
    { name: "Name", selector: (r) => r.Name },
    { name: "Issuer", selector: (r) => r.Issuer },
    { name: "ID", selector: (r) => r.ID },
    { name: "PX Last", selector: (r) => r.PXLast },
    { name: "Open", selector: (r) => r.Open },
    { name: "High", selector: (r) => r.High },
    { name: "Low", selector: (r) => r.Low },
    { name: "Close", selector: (r) => r.Close },
    { name: "Prev Close", selector: (r) => r.PrevClose },
    { name: "Volume", selector: (r) => r.Volume },
  ];

  const data = [];
  return (
    <Box>
      <NTable columns={columns} data={data} />
    </Box>
  );
};

export default PortfolioTab;
