import { Paper, Box } from "@mui/material";
import React, { useEffect, useRef, useState, useContext } from "react";

import { createChart, PriceScaleMode, LineStyle } from "lightweight-charts";
import data from '../../json/chart.json';
import { grey ,blue} from '@mui/material/colors';
import { ThemeModeContext } from "../../CustomThemeProvider";

const SecurityChart = () => {

    const [themeMode, setThemeModel] = useContext(ThemeModeContext);

    let chartRef = useRef([])
    let chatOptions = {
        leftPriceScale: {
            scaleMargins: {
                top: 0.2,
                bottom: 0.2,
            },
            visible: true,
            borderVisible: false,
        },
        layout: {
            backgroundColor: themeMode == "dark" ? grey[800] : "transparent",
            textColor: themeMode == "dark" ? "#ffffff" : "#000",
        },
        rightPriceScale: {
            visible: false,
        },
        timeScale: {
            borderVisible: false,
        },
        grid: {
            vertLines: {
                color: "rgba(0, 0, 0, 0)",
                style: LineStyle.Dotted,
            },
            horzLines: {
                color: "rgba(197, 203, 206, 0.4)",
                style: LineStyle.Dotted,
            },
        },
    };

    useEffect(() => {
        console.log(chartRef?.current.innerWidth)
        const chart = createChart(chartRef.current, {
            ...chatOptions, label: "MS"
        }
        );

        let areaSeriesOption = {
            topColor: "transparent",
            bottomColor: "transparent",
            lineColor: blue[themeMode =="dark" ? 800 : 200],
            lineWidth: 2,
            title: "MS",
        }
        let areaSeries = chart.addAreaSeries(areaSeriesOption);
        areaSeries.setData(data["tySet2"]);
        const ro = new ResizeObserver((entries) => {
            const cr = entries[0].contentRect;
            resize(cr.width, cr.height);
          });
      
          ro.observe(chartRef.current);
      
          if (chartRef.current.innerWidth) {
            window.addEventListener("resize", () => {
              resize(chartRef.current.innerWidth, chartRef.current.innerHeight);
            });
          }
      
          const resize = (width, height) => {
            chart.resize(width, height);
          };
      
          chart.timeScale().fitContent();

        return () => {
            chart.remove()
        }

    }, [chartRef, themeMode])

    return (
        <Box sx={{ px:1 }}>
            <Paper sx={{height:500}} ref={(el) => chartRef.current = el}>
            </Paper>
        </Box>

    )
}

export default SecurityChart
