import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';

import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


import DTable from '../../../components/HigherOrderComponent/DTable';
import { Grid, Paper } from '@mui/material';

export default function PerformanceReport() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])


    const getRowData = () => {
        let d = localStorage.getItem("alLPerformanceReports");
        setData(d ? JSON.parse(d) : []);
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "alLPerformanceReports", collectionId: "report_id", selectedId: selectedRow.report_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values,collectionName: "alLPerformanceReports", collectionId: "report_id", selectedId: selectedRow ? selectedRow.report_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
        }, 2000)
    }



    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedFlatRows, selectedRowIds }) => {
        setSelectedRow(selectedFlatRows[0] ? selectedFlatRows[0].original : null);
    }

    const fundsFormData = [
        {
            label: "Fund Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "fund_name",
            validations: yup.string().required("please enter fund name"),

        },
        {
            label: "Strategy Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "strategy_name",
            validations: yup.string().required("please enter strategy name"),

        },
        {
            label: "Report Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "report_name",
            validations: yup.string().required("please enter report name"),

        },
        {
            label: "File:",
            label_col: 5,
            input_col: 7,
            type: "file",
            name: "file",
            validations: yup.mixed().required("please select file"),

        },
    ];

    const columns =  [
        {
            name: "Report ID",
            selector:r=>r.report_id,
        },
        {
            name: "Fund Name",
            selector:r=>r.fund_name,
        },
        {
            name: "Strategy Name",
            selector:r=>r.strategy_name,
        },
        {
            name: "Report Name",
            selector:r=>r.report_name,
        },
    ];
    


    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });


    return (
        <>
        <Grid container spacing={2}>
            <Grid item md={8}>
                <Paper>
                    <DTable
                        title="Performance Report"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        dense
                        selectableRows
                        selectableRowsSingle
                        responsive
                        fixedHeader
                        onSelectedRowsChange={onRowSelect}
                        clearSelectedRows={toggledClearRows}
                        fixedHeaderScrollHeight="50%"
                    />
                </Paper>
            </Grid>
            <Grid item md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Performance Report"}
                />
            </Grid>
        </Grid>
    </>
    )
}


