import { useState, useEffect } from "react";

import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import {
  Card,
  CardContent,
  Skeleton,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";

import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/material/Chip";
import StatusTag from "../../components/HigherOrderComponent/StatusTag";

const Cell = styled(TableCell)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  "&:last-of-type": {
    borderLeft: 0,
  },
  "&:first-of-type": {
    borderRight: 0,
  },
}));

const percentage = (percent, total) => {
  return ((percent / 100) * total).toFixed(2);
};

const initialvalue = {
  settleDate: null,
  riskGroup: "",
  tradeDate: null,
  buySell: "buy",
  ccy: "USD",
  ticker: "",
  notional: 100,
  price: "",
  name: "Morgan Stanley",
  comments: "",
  status: "save",
  subRow: [
    {
      block1: "213",
      allocation: "",
      fund: "",
      strategy: "",
      substrategy: "",
      notional: 0,
      price: 140,
      ccy: "USD",
      mv: "5,600",
      ccyBase: "USD",
      mvBase: "5,600",
      fundPre: "0.02%",
      fundPost: "0.06%",
      estRisk: "0.00%",
      estRiskBase: 280,
      cash: "4.95%",
      cashBase: "4,94,400",
      casht1: "4,94,400",
      casht2: "4,94,400",
    },
  ],
};

const validationSchema = yup.object({
  tradeDate: yup.date().required("Required"),
  settleDate: yup.string().required("Required"),
  riskGroup: yup.string().required("Required"),
  buySell: yup.string().required("Please select group type"),
  ticker: yup.string().required("Required"),
  name: yup.string().required("Required"),
  notional: yup.number().required("Required").min(100),
  price: yup.number().typeError("Required").required("Required"),
  comments: yup.string(),
  status: yup.string().default("save").required().oneOf(["save", "submit"]),
  subRow: yup.array().of(
    yup.object().shape({
      allocation: yup.number().when(["notional"], {
        is: (notional) => !notional,
        then: yup.number().required().max(100).min(0),
      }),
      fund: yup.string().required("Fund required"),
      strategy: yup.string().required("Strategy required"),
      substrategy: yup.string().required("Sub Strategy required"),
      notional: yup.number().typeError("Required").required("Required"),
    })
  ),
});

const TableFormGenerator = ({
  formValues = null,
  handleOnSubmit,
  deleteDataHandler,
}) => {
  const [formData, setFormData] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    formValues && setFormData(formValues);
  }, [formValues]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Formik
          enableReinitialize={true}
          initialValues={formData ?? initialvalue}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
          // onReset={onReset}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            isSubmitting,
            submitForm,
          }) => (
            <Box component="form" noValidate autoComplete="off">
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  dense
                  onClick={(e) => {
                    setFieldValue("status", "submit");
                    submitForm();
                  }}
                >
                  Submit
                </MenuItem>
                <MenuItem
                  dense
                  onClick={(e) => {
                    setFieldValue("status", "save");
                    submitForm();
                  }}
                >
                  Save
                </MenuItem>
                <Divider />
                <MenuItem dense onClick={(e) => deleteDataHandler?.()}>
                  Delete
                </MenuItem>
              </Menu>
              <Grid container spacing={1.5} columns={24}>
                {/* Top section */}
                <Grid item md={1.75}>
                  <TextField
                    label="Buy/Sell"
                    variant="outlined"
                    size="small"
                    value={values.buySell}
                    name="buySell"
                    select
                    onBlur={handleBlur}
                    error={touched["buySell"] && !!errors["buySell"]}
                    helperText={touched["buySell"] && errors["buySell"]}
                    fullWidth={true}
                    onChange={handleChange}
                  >
                    <MenuItem value="buy">Buy</MenuItem>
                    <MenuItem value="sell">Sell</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={2.35}>
                  <DatePicker
                    label="Trade Date"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={values.tradeDate}
                    onChange={(date) => {
                      setFieldValue("tradeDate", date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={touched["tradeDate"] && !!errors["tradeDate"]}
                        helperText={touched["tradeDate"] && errors["tradeDate"]}
                        {...params}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={2.5}>
                  <DatePicker
                    label="Settle Date"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={values.settleDate}
                    onChange={(date) => {
                      setFieldValue("settleDate", date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={touched["settleDate"] && !!errors["settleDate"]}
                        helperText={
                          touched["settleDate"] && errors["settleDate"]
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2.5}>
                  <Autocomplete
                    disableClearable
                    onChange={(event, newValue) => {
                      setFieldValue("ticker", newValue.ticker);
                      setFieldValue("name", newValue.name);
                      setFieldValue("price", newValue.price);
                    }}
                    getOptionLabel={(option) => option.ticker}
                    options={[
                      { name: "Morgan Stanley", ticker: "MS", price: 150 },
                      { name: "Apple", ticker: "AAPL", price: 540 },
                    ]}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <strong>{option.ticker}</strong>(
                        <small>{option.name}</small>)
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Ticker"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2.15}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    error={touched["name"] && !!errors["name"]}
                    helperText={touched["name"] && errors["name"]}
                    fullWidth={true}
                    InputLabelProps={{ shrink: !!values.name }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={1.5}>
                  <TextField
                    label="Notional"
                    variant="outlined"
                    size="small"
                    value={values.notional}
                    name="notional"
                    onBlur={handleBlur}
                    error={touched["notional"] && !!errors["notional"]}
                    helperText={touched["notional"] && errors["notional"]}
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={1.5}>
                  <TextField
                    label="price"
                    variant="outlined"
                    size="small"
                    value={values.price}
                    name="price"
                    onBlur={handleBlur}
                    error={touched["price"] && !!errors["price"]}
                    helperText={touched["price"] && errors["price"]}
                    InputLabelProps={{ shrink: !!values.price }}
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item md={1.5}>
                  <TextField
                    label="CCY"
                    variant="outlined"
                    size="small"
                    value={values.ccy}
                    name="ccy"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched["ccy"] && !!errors["ccy"]}
                    helperText={touched["ccy"] && errors["ccy"]}
                    fullWidth={true}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    label="Risk"
                    variant="outlined"
                    size="small"
                    value={values.riskGroup}
                    name="riskGroup"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched["riskGroup"] && !!errors["riskGroup"]}
                    helperText={touched["riskGroup"] && errors["riskGroup"]}
                    fullWidth={true}
                    select
                  >
                    {["R1", "R1", "R1"].map((r, i) => (
                      <MenuItem key={i} value={r}>
                        {r}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3.75}>
                  <TextField
                    label="comments"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SendIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    value={values.comments}
                    name="comments"
                    onBlur={handleBlur}
                    error={touched["comments"] && !!errors["comments"]}
                    helperText={touched["comments"] && errors["comments"]}
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={1.25}>
                  {values.status && (
                    <StatusTag
                      label={
                        values.status == "save" && formData?.order_id
                          ? "Draft"
                          : values.status
                      }
                    />
                  )}
                </Grid>
                <Grid item md={0.5}>
                  <IconButton onClick={handleClick}>
                    <MoreHorizIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <FieldArray
                  name="subRow"
                  render={(arrayHelpers) => {
                    const subRow = values.subRow;
                    return (
                      <>
                        {subRow && subRow.length > 0
                          ? subRow.map((data, index) => (
                              <>
                                <Grid container spacing={1.5} columns={24}>
                                  <Grid item md={1.75}>
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        arrayHelpers.push(
                                          initialvalue.subRow[0]
                                        )
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>

                                    {index !== 0 && (
                                      <IconButton size="small">
                                        <DeleteForeverIcon
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item md={2}>
                                    <TextField
                                      label="Alloc(%)"
                                      variant="outlined"
                                      size="small"
                                      name={`subRow.${index}.allocation`}
                                      type={"number"}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `subRow.${index}.allocation`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `subRow.${index}.notional`,
                                          values.subRow[index]["allocation"] &&
                                            values.notional
                                            ? percentage(
                                                e.target.value,
                                                values.notional
                                              )
                                            : 0,
                                          false
                                        );
                                      }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <TextField
                                      label="Funds"
                                      variant="outlined"
                                      size="small"
                                      name={`subRow.${index}.fund`}
                                      onBlur={handleBlur}
                                      select
                                      fullWidth={true}
                                      inputProps={{
                                        size: 10,
                                      }}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value="F1">F1</MenuItem>
                                      <MenuItem value="F2">F2</MenuItem>
                                      <MenuItem value="F3">F3</MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <TextField
                                      label="Strategy"
                                      variant="outlined"
                                      size="small"
                                      name={`subRow.${index}.strategy`}
                                      select
                                      fullWidth={true}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value="S1">S1</MenuItem>
                                      <MenuItem value="S2">S2</MenuItem>
                                      <MenuItem value="S3">S3</MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item md={2.5}>
                                    <TextField
                                      label="Sub Strategy"
                                      variant="outlined"
                                      size="small"
                                      select
                                      fullWidth={true}
                                      name={`subRow.${index}.substrategy`}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    >
                                      {[
                                        { title: "S1S1", value: "S1S1" },
                                        { title: "S1S2", value: "S1S2" },
                                        { title: "S1S3", value: "S1S3" },
                                        { title: "S2S1", value: "S2S1" },
                                        { title: "S2S2", value: "S2S2" },
                                        { title: "S2S3", value: "S2S3" },
                                        { title: "S3S1", value: "S3S1" },
                                        { title: "S3S2", value: "S3S2" },
                                        { title: "S3S3", value: "S3S3" },
                                      ].map((v, i) => (
                                        <MenuItem key={i} value={v.value}>
                                          {v.title}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={1.5}>
                                    <TextField
                                      label="Notional"
                                      variant="outlined"
                                      size="small"
                                      value={
                                        values?.subRow?.[index]?.notional ??
                                        "NA"
                                      }
                                      readOnly
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth={true}
                                    />
                                  </Grid>

                                  <Grid item md={1.5}>
                                    <TextField
                                      label="Price"
                                      variant="outlined"
                                      size="small"
                                      value={
                                        values?.subRow?.[index]?.price ?? "NA"
                                      }
                                      readOnly
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth={true}
                                    />
                                  </Grid>
                                  <Grid item md={1.5}>
                                    <TextField
                                      label="CCY"
                                      variant="outlined"
                                      size="small"
                                      value={
                                        values?.subRow?.[index]?.ccy ?? "NA"
                                      }
                                      readOnly
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth={true}
                                    />
                                  </Grid>
                                </Grid>

                                <TableContainer sx={{ mt: 1, mb: 2 }}>
                                  <Table size="small">
                                    <TableHead>
                                      <Cell>Mv</Cell>
                                      <Cell>Ccy Base</Cell>
                                      <Cell>Mv Base</Cell>
                                      <Cell>Fund Pre</Cell>
                                      <Cell>Fund Post</Cell>
                                      <Cell>Est Risk</Cell>
                                      <Cell>Est Risk Base</Cell>
                                      <Cell>Cash</Cell>
                                      <Cell>CashBase</Cell>
                                      <Cell>Cash t1</Cell>
                                      <Cell>Cash t2</Cell>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <Cell>{data.mv}</Cell>
                                        <Cell>{data.ccyBase}</Cell>
                                        <Cell>{data.mvBase}</Cell>
                                        <Cell>{data.fundPre}</Cell>
                                        <Cell>{data.fundPost}</Cell>
                                        <Cell>{data.estRisk}</Cell>
                                        <Cell>{data.estRiskBase}</Cell>
                                        <Cell>{data.cash}</Cell>
                                        <Cell>{data.cashBase}</Cell>
                                        <Cell>{data.casht1}</Cell>
                                        <Cell>{data.casht2}</Cell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            ))
                          : null}
                      </>
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default TableFormGenerator;
