import { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from '../helpers/PrivateRoute'
import Login from '../containers/Login/Login'
import Layout from '../components/Layout/Layout'
import { useKeycloak } from '@react-keycloak/web';
import Preloader from '../components/common/Spinner'
import AuthService from "../services/auth";

const Routes = () => {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600).then(() => {
        AuthService.setToken(keycloak.token);
      });
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => { };
    };
  }, [initialized, keycloak]);

  return (
    initialized ?
      (<BrowserRouter>
        <Switch>
          <Route path='/login' component={Login} />
          <PrivateRoute path='/' component={Layout} />
          <Redirect to='/dashboard' from='/' />
        </Switch>
      </BrowserRouter>)
      : 
      <Preloader />
  )
}
export default Routes
