import { useState, useContext, Fragment } from "react";

import { Link } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import printJS from 'print-js'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';


import { IconButton, Paper, Toolbar, Typography } from '@mui/material';
import { Divider, Menu, MenuItem, List, Box, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Switch } from '@mui/material';


import { styled, useTheme } from '@mui/material/styles';

import { ThemeModeContext } from "../../CustomThemeProvider";
import SecurityIcon from '@mui/icons-material/Security';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TerminalIcon from '@mui/icons-material/Terminal';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
const DRAWER_WIDTH = 240;


const SelectedItem = styled('div')(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.dark,
  width: "6px",
  height: "100%",
  left: "0%",
  borderRadius: "0 60px 60px 0"
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});




const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const Header = (props) => {
  const { i, keycloak } = useKeycloak();
  const [open, setOpen] = useState(false);

  const [themeMode, setThemeModel] = useContext(ThemeModeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuopen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();


  const menuItems = [
    {
      items: [
        { title: "Dashboard", path: '/dashboard', icon: <DashboardIcon /> },
        { title: "Portfolio", path: '/portfolio', icon: <WorkOutlineIcon /> },
        { title: "Securities", path: '/security', icon: <SecurityIcon /> },
        { title: "Order", path: '/order', icon: <ShoppingCartIcon /> },
        { title: "Trade", path: '/trade', icon: <BusinessCenterIcon /> },
        { title: "Risk Management", path: '/risk-management', icon: <ReportProblemIcon /> },
      ],
      divider: true,
    },
    {
      menuTitle: "OPERATION",
      items: [
        { title: "Fund Admin", path: '/fund-admin', icon: <AdminPanelSettingsIcon /> },
        { title: "OP Console", path: '/op-console', icon: <TerminalIcon /> }
      ],
      divider: true,

    },
    {
      menuTitle: "ADMIN",
      items: [{ title: "Profile", path: '/profile', icon: <AssignmentIndIcon /> },
      { title: "HR", path: '/hr', icon: <AccessibilityIcon /> },
        // { title: "security", path: '/profile' }
      ]
    }
  ]






  const printDoc = () => {
    document.querySelector('body').style.overflow = 'auto'
    document.querySelector('body').style.height = 'auto'
    document.getElementById('root').style.height = 'auto'
    html2canvas(document.body, {
      onrendered: function (canvas) {
        canvas.toBlob(function (blob) {
          const urlCreator = window.URL || window.webkitURL
          const imageUrl = urlCreator.createObjectURL(blob)
          printJS({
            printable: imageUrl,
            type: 'image',
            header: `Hello User`,
            headerStyle: 'font-weight: 300; font-size: 14px;'
          })
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0;
          document.querySelector('body').style.overflow = 'hidden'
          document.querySelector('body').style.height = '100vh'
          document.getElementById('root').style.height = '100vh'
        });
      }
    });
  }

  const isActiveLink = (path) => {
    return (window.location.pathname === path || window.location.pathname.includes(path));
  };


  const logoutHandler = () => {
    keycloak.logout()
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };




  const toggleTheme = () => {
    setThemeModel(themeMode == 'light' ? 'dark' : 'light');
  }









  return (
    <>
      <AppBar position="absolute" open={open}  >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            DAirFinance
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton sx={{ ml: 1 }} onClick={toggleTheme} >
              {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            <IconButton
              sx={{ ml: 1 }}
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton>
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>


            <Tooltip title="Account settings">
              <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="small" sx={{ ml: 2 }}>
                <Avatar sx={{ width: 32, height: 32 }}>{keycloak?.tokenParsed?.given_name?.charAt(0)}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={menuopen}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Avatar fontSize="small" />
                </ListItemIcon>
                {keycloak?.tokenParsed?.given_name}
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={logoutHandler}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

          </Box>

        </Toolbar>

      </AppBar>


      <Drawer variant="permanent" open={open}>
        <Toolbar variant="dense" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Toolbar>
        <Divider />
        {
          menuItems.map((subMenu, i) => (
            <Fragment key={i}>
              <List component="nav" dense>
                {(subMenu.menuTitle && open) && <ListSubheader dense >{subMenu.menuTitle}</ListSubheader>}
                {
                  subMenu.items.map((item, j) => (
                    <ListItemButton component={Link} to={item.path} key={j} selected={isActiveLink(item.path)}>
                      {isActiveLink(item.path) && <SelectedItem></SelectedItem>}
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  ))
                }
                {subMenu.divider && <Divider />}
              </List>
            </Fragment>
          ))
        }
      </Drawer>
    </>
  )

};

export default Header;
