import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator":{
      top:"0",
      height:"5px",
      borderRadius:" 0 0 60px 60px",
      backgroundColor: theme.palette.primary[theme.mode],
    }
  }));

export default CustomTabs;

