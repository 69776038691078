import { useRef, useEffect, useContext } from "react";
import { createChart, LineStyle, CrosshairMode } from "lightweight-charts";
import { grey, blue } from "@mui/material/colors";
import { Box, Paper, Grid, Card, CardContent } from "@mui/material";
import { ThemeModeContext } from "../../CustomThemeProvider";
import { useTheme } from "@mui/material/styles";

import data2 from "../../json/chart.json";
let data = [
  { label: "Symbol:", value: "ID / ISIN" },
  { label: "Full Name:", value: "CAP NAT" },
  { label: "Sector:", value: "ISSUE" },
  { label: "Outstanding Share:", value: "1000" },
];
const Snapshot = () => {
  return (
    <Card>
      <CardContent>
        {data.map((column, column_key) => (
          <Grid container spacing={0} key={column_key}>
            <Grid item md={3}>
              {column.label}
            </Grid>
            <Grid item md={3}>
              {column.value}
            </Grid>
          </Grid>
        ))}
        <ChartElement />
      </CardContent>
    </Card>
  );
};

const ChartElement = () => {
  const [themeMode, setThemeModel] = useContext(ThemeModeContext);

  const theme = useTheme();

  let chatOptions = {
    leftPriceScale: {
      scaleMargins: {
        top: 0.2,
        bottom: 0.2,
      },
      visible: true,
      borderVisible: false,
    },
    layout: {
      backgroundColor:
        themeMode == "dark" ? "transparent" : theme.palette.background.paper,
      textColor: themeMode == "dark" ? "#fff" : "#000",
    },
    rightPriceScale: {
      visible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    grid: {
      vertLines: {
        color: blue[themeMode == "dark" ? 50 : 800],
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
  };

  const chartRef = useRef([]);
  useEffect(() => {
    const chart = createChart(chartRef.current, {
      ...chatOptions,
      label: "Trade",
    });
    let candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data2.fxChart);
    // resize observer (native JS)
    const ro = new ResizeObserver((entries) => {
      const cr = entries[0].contentRect;
      resize(cr.width, cr.height);
    });

    ro.observe(chartRef.current);

    if (chartRef.current.innerWidth) {
      window.addEventListener("resize", () => {
        resize(chartRef.current.innerWidth, chartRef.current.innerHeight);
      });
    }

    const resize = (width, height) => {
      chart.resize(width, height);
    };

    chart.timeScale().fitContent();

    return () => {
      chart.remove();
    };
  }, [chartRef, themeMode]);
  return <Box sx={{ height: 440 }} ref={(el) => (chartRef.current = el)} />;
};

export default Snapshot;
