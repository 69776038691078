import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const CropperModal = ({ img }) => {
  const [open, setOpen] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [cropperData, setCropData] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(!!img);
    console.log({ img });
  }, [img]);

  const cropperRef = useRef(null);

  const applyCrop = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Cropper
          src={img}
          style={{ height: 350, width: "100%" }}
          initialAspectRatio={4 / 4}
          guides={true}
          responsive
          viewMode={1}
          ref={cropperRef}
          checkOrientation={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={applyCrop}>Crop</Button>
        <Button onClick={handleClose}>close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropperModal;
