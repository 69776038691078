import React, { useState, useEffect } from "react";

import { AppBar, Toolbar } from "@mui/material";
import { Box, Tab } from "@mui/material";
import CustomTabs from "../../components/common/CustomTabs";
import ListTab from "../Trade/ListTab";
import PortfolioTab from "../Trade/PortfolioTab";
import AnalyticsTab from "../Trade/AnalyticsTab";
import EventsTab from "../Trade/EventsTab";
const SecurityBottomTabs = () => {
  const tabsList = ["List", "Portfolio", "Analytics", "Events"];
  const [selectedTab, setSelectedTab] = useState(tabsList[0]);

  const componentsList = {
    List: <ListTab />,
    Portfolio: <PortfolioTab />,
    Analytics: <AnalyticsTab />,
    Events: <EventsTab />,
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <CustomTabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
        >
          {tabsList.map((tab, key) => (
            <Tab label={tab} value={tab} key={key} />
          ))}
        </CustomTabs>
      </AppBar>
      <Box>{componentsList[selectedTab]}</Box>
    </>
  );
};

export default SecurityBottomTabs;
