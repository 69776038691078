import React, { useState, useEffect } from "react";

import { Toolbar } from "@mui/material";
import { Autocomplete, TextField, Box, Tabs, Tab, Grid } from '@mui/material';


import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import CancelIcon from '@mui/icons-material/Cancel';
import SecurityDetails from "./SecurityDetails";
import SecurityChart from "./SecurityChart";
import SecurityBottomTabs from "./SecurityBottomTabs";


const Security = () => {
  const [seletedSecurities, setSeletedSecurities] = useState([]);
  const [selectedTab, setSelectedTab] = useState(false);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  const removeTabHandler = (index) => {
    const newArray = seletedSecurities.slice()
    newArray.splice(index, 1);
    setSeletedSecurities(newArray);
    newArray.length > 0 ? setSelectedTab(newArray.at(-1)) : setSelectedTab(false);
  }
  return (<>
      <Toolbar>
        <Autocomplete
          sx={{ width: 300, mr: 3 }}
          onChange={(event, newValue) => {
            if (newValue) {
              let data = seletedSecurities.slice()
              data.push(newValue.ticker)
              data = new Set(data)
              setSeletedSecurities([...data])
              setSelectedTab(newValue.ticker);
            }
          }}
          getOptionLabel={option => option.ticker}
          options={[{ name: "Morgan Stanley", ticker: "MS", price: 150, type: "equity" }, { name: "Apple", ticker: "AAPL", price: 540, type: "equity" }]}
          renderOption={(props, option) => (
            <ListItem component="li" {...props}>
              <ListItemText
                primary={<><strong>{option.ticker}</strong> <small>{option.name}</small></>}
                secondary={option.type ?? ""}
              >

              </ListItemText>
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Ticker"
              fullWidth={true}
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />

        {selectedTab && <Tabs value={selectedTab} onChange={handleTabChange}>
          {seletedSecurities.map((seletedSecurity, index) => {
            return (
              <Tab
                icon={<CancelIcon onClick={() => removeTabHandler(index)} sx={{ fontSize: 20 }} />}
                iconPosition="end"
                key={index} label={seletedSecurity} value={seletedSecurity}></Tab>
            )
          })
          }
        </Tabs>}

      </Toolbar>

    {
      selectedTab && (<>
        <Box>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"

          >
            <Grid item md={5}>
              <SecurityDetails />
            </Grid>
            <Grid item md={7}>
              <SecurityChart />
            </Grid>

          </Grid>
        </Box>
        <Box>
            <SecurityBottomTabs />
        </Box>
      </>)
    }
  </>)
}

export default Security;
