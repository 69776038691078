import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';

import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';


import DTable from '../../../components/HigherOrderComponent/DTable';
import { Grid, Paper } from '@mui/material';

export default function SubStrategy() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])

    const getRowData = () => {
        let d = localStorage.getItem("allSubStrategies");
        setData(d ? JSON.parse(d) : []);
    }

    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allSubStrategies", collectionId: "sub_strategy_id", selectedId: selectedRow.sub_strategy_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values,collectionName: "allSubStrategies", collectionId: "sub_strategy_id", selectedId: selectedRow ? selectedRow.sub_strategy_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedRows }) => {
        setSelectedRow(selectedRows[0] ?? null)
    }

    const fundsFormData = [
        {
            label: "Strategy Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "strategy_name",
            validations: yup.string().required("please enter strategy name"),

        },
        {
            label: "Sub-Strategy Name:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "sub_strategy_name",
            validations: yup.string().required("please enter sub strategy name"),
        },
        {
            label: "Description:",
            type: "text",
            label_col: 5,
            input_col: 7,
            name: "description",
            validations: yup.string().required("please enter description"),
        },
    ];

    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });

    let columns = [
        {
            name: "Sub-Strategy Id",
            selector:row=>row.sub_strategy_id,
        },
        {
            name: "Strategy Name",
            selector:row=>row.strategy_name,
        },
        {
            name: "Sub-Strategy Name",
            selector:row=>row.sub_strategy_name,

        },
        {
            name: "Description",
            selector:row=>row.description,
        },
    ];


    return (
        <>
        <Grid container spacing={2}>
            <Grid item md={8}>
                <Paper>
                    <DTable
                        title="Sub Strategy"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        dense
                        selectableRows
                        selectableRowsSingle
                        responsive
                        fixedHeader
                        onSelectedRowsChange={onRowSelect}
                        clearSelectedRows={toggledClearRows}
                        fixedHeaderScrollHeight="50%"
                    />
                </Paper>
            </Grid>
            <Grid item md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Sub-Strategy Details"}
                />
            </Grid>
        </Grid>
        </>
    )
}
