import { useEffect, useState } from 'react'
import FormGenerator from '../FormGenerator';
import DTable from '../../../components/HigherOrderComponent/DTable';


import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ConfirmDialog } from '../../../components/Confirmation';
import { deleteFromLocal, saveToLocal } from '../../../common/localStorage.service';
import { Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';


export default function Fund() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getRowData();
            setLoading(false)
        }, 3000)
    }, [])

    const fundsFormData = [
        {
            label: "Fund Name:",
            type: "text",
            name: 'fund_name',
            validations: yup
                .string()
                .required("Please enter fund name")

        },
        {
            label: "Investment Type:",
            type: "text",
            name: 'investment_type',
            validations: yup.string().required("please enter Investment type"),
        },
        {
            label: "Leading PM:",
            type: "text",
            name: 'leading_pm',
            validations: yup.string().required("please enter Leading PM")
        },
        {
            label: "Tax Code:",
            type: "text",
            name: 'tax_code',
            validations: yup.string().required("please enter Tax Code")
        },
        {
            label: "Legal Structure:",
            type: "text",
            name: 'legal_structure',
            validations: yup.string().required("please enter legal structure")
        },
        {
            label: "Custodian:",
            type: "text",
            name: 'custodian',
            validations: yup.string().required("please enter Custodian")
        },
        {
            label: "Registration:",
            type: "text",
            name: 'registration',
            validations: yup.string().required("please enter registration")
        },
        {
            label: "Legal Representation:",
            type: "text",
            name: 'legal_representation',
            validations: yup.string().required("please enter legal representation")
        },
        {
            label: "Base Currency",
            type: "text",
            name: 'base_currency',
            validations: yup.string().required("please enter base currency")
        },
        {
            label: "Inception Date:",
            type: "date",
            name: 'inception_date',
            validations: yup.date().required("please select inception date")
        },
        {
            label: "Descriptions:",
            type: "text",
            name: 'description',
            validations: yup.string().required("please enter descriptions")
        },
        {
            label: "Fund Prospectus",
            type: "text",
            name: 'fund_prospectus',
            validations: yup.string().required("please enter fund prospectus")
        },
    ];

    const getRowData = () => {
        let d = localStorage.getItem("allFunds");
        setData(d ? JSON.parse(d) : []);
    }
    let schema = yup.object().shape({
        ...fundsFormData.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.validations ?? "" }), {})
    });



    const deleteHandler = (cb) => {
        deleteFromLocal({ collectionName: "allFunds", collectionId: "fund_id", selectedId: selectedRow.fund_id })
        setSelectedRow(null);
        getRowData();
        cb();
        return;
    }

    const onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
        setSubmitting(true);
        setTimeout(() => {
            saveToLocal({ values, collectionName: "allFunds", collectionId: "fund_id", selectedId: selectedRow ? selectedRow.fund_id : null });
            setSubmitting(false);
            setToggleClearRows(!toggledClearRows);
            setSelectedRow(null);
            resetForm();
            getRowData();
            enqueueSnackbar(`Funded ${selectedRow ? "added" :"update" } successfully`,{
                variant:"success"
            });
        }, 2000)
    }

    const onDelete = () => {
        if (selectedRow) {
            {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return <ConfirmDialog onClose={onClose} onConfirm={deleteHandler} />
                    }
                });
            }
        }
    }

    const onRowSelect = ({ selectedRows }) => {
        setSelectedRow(selectedRows[0] ?? null)
    }

    const columns = [

        {
            name: "Fund ID",
            selector: row => row.fund_id,
        },
        {
            name: "Fund Name",
            selector: row => row.fund_name,
        },
        {
            name: "Investment Type",
            selector: row => row.investment_type,
        },
        {
            name: "Leading PM",
            selector: row => row.leading_pm,
        },
        {
            name: "Tax Code",
            selector: row => row.tax_code,
        },
        {
            name: "Legal Structure",
            selector: row => row.legal_structure,
        },
        {
            name: "Custodian",
            selector: row => row.custodian,
        },
        {
            name: "Legal Representation",
            selector: row => row.legal_representation,
        },
        {
            name: "Base Currency",
            selector: row => row.base_currency,
        },
        {
            name: "Inception Date",
            selector: row => row.inception_date,
        },
        {
            name: "Description",
            selector: row => row.description,
        },
    ]

    return (
        <Grid container spacing={2}>
            <Grid item md={8}>
                <Paper>
                    <DTable
                        title="Fund"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        dense
                        selectableRows
                        selectableRowsSingle
                        responsive
                        fixedHeader
                        onSelectedRowsChange={onRowSelect}
                        clearSelectedRows={toggledClearRows}
                        fixedHeaderScrollHeight="50%"
                    />
                </Paper>
            </Grid>
            <Grid item md={4}>
                <FormGenerator
                    formData={fundsFormData}
                    initialData={selectedRow}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    schema={schema}
                    title={"Fund Details"}
                />
            </Grid>
        </Grid>
    )

}


