import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Box } from '@mui/system';
import { TextField, MenuItem } from '@mui/material';
import { Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardHeader, Card, CardContent, CardActions } from '@mui/material';

import { format } from 'date-fns'
const inputFactor = (config) => {
    switch (config.formData.type) {
        case "date":
            return dateInputFeild(config);
        case "text":
            return TextInputFeild(config);
        case "text-area":
            return TextInputFeild({ ...config, ...{ multiline: true } });
        case "select":
            return SelectInputFeild(config);
        case "file":
            return FileInputFeild(config);
        default:
            return TextInputFeild(config);

    }
}

function dateInputFeild(config) {
    return (
        <DatePicker
            label={config.formData.label}
            openTo="day"
            orientation="landscape"
            views={['year', 'month', 'day']}
            value={config.values[config.formData.name] =="" ? null : config.values[config.formData.name]}
            onChange={(date) => {
               config.setFieldValue(config.formData.name, date)
            }}

            renderInput={(params) => <TextField
                fullWidth={true}
                variant="standard"
                onBlur={config.handleBlur}
                error={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
                helperText={config.touched[config.formData.name] && config.errors[config.formData.name]}

                {...params}
            />}



        />

    );


}

function TextInputFeild(config) {
    return (
        <>

            <TextField
                label={config.formData.label}
                helperText={config.touched[config.formData.name] && config.errors[config.formData.name]}
                name={config.formData.name}
                value={config.values[config.formData.name]}
                onChange={config.handleChange}
                onBlur={config.handleBlur}
                error={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
                variant="standard"
                multiline={config.multiline}
                rows={config.multiline && config.formData.maxRows}
                fullWidth
            />
        </>
    )
}
function SelectInputFeild(config) {
    return (
        <>
            <TextField
                select
                label={config.formData.label}
                helperText={config.touched[config.formData.name] && config.errors[config.formData.name]}
                name={config.formData.name}
                value={config.values[config.formData.name]}
                onChange={config.handleChange}
                onBlur={config.handleBlur}
                error={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
                variant="standard"
                fullWidth
            >
                {config.formData.options.map((option, index) => {
                    return (
                        <MenuItem key={index} value={option.value ?? option}>{option.title ?? option}</MenuItem>
                    )
                })}
            </TextField>
        </>
    )

}

function FileInputFeild(config) {
    return (
        <><TextField
            type="file"
            helperText={config.touched[config.formData.name] && config.errors[config.formData.name]}
            name={config.formData.name}
            onBlur={config.handleBlur}
            error={config.touched[config.formData.name] && !!config.errors[config.formData.name]}
            variant="standard"
            fullWidth
        />
        </>
    );
}


const FormGenerator = ({ formData, onSubmit, schema, title, initialData, onDelete }) => {
    const getInitialData = () => {
        let temp = {}
        formData.forEach(_ => temp[_.name] = "");
        return temp
    }

    let [initialValues, setInitalValue] = useState(getInitialData());
    let [edit, setEdit] = useState(false);


    useEffect(() => {
        setEdit(!!initialData)
        setInitalValue(initialData ?? getInitialData());
    }, [initialData]);



    const deleteHandler = () => {
        onDelete?.();
    }

    return (
        <Card sx={{ p: 2 }} elevation={6}>
            <CardHeader title={title} />
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={{ ...initialValues }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                    submitForm

                }) => (
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off" onSubmit={handleSubmit}

                    >
                        <CardContent>
                            <Stack spacing={0.5}>

                                {
                                    formData.map((formData, key) => {
                                        return (
                                            <div key={key}>
                                                {
                                                    inputFactor({ formData, values, touched, errors, handleChange, handleBlur, setFieldValue })

                                                }
                                            </div>
                                        )
                                    })
                                }

                            </Stack>
                        </CardContent>
                        <CardActions disableSpacing>
                            {/* <Button className="mr-1" size="sm" variant="primary" type="submit">
                            {
                                isSubmitting ?
                                    (<>   <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                        <span className="visually-hidden">Loading...</span></>)
                                    : edit ? "Update" : "Add new"
                            }
                        </Button> */}

                            <LoadingButton
                                onClick={submitForm}
                                loading={isSubmitting}
                                loadingPosition="start"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                {edit ? "Update" : "Add new"}
                            </LoadingButton>
                            {
                                edit &&
                                <Button
                                    startIcon={<DeleteIcon />}
                                    variant="contained"
                                    color="error"
                                    sx={{ marginLeft: 2 }}
                                    onClick={deleteHandler}
                                >
                                    Delete
                                </Button>
                            }

                        </CardActions>
                    </Box>

                )}

            </Formik>
        </Card>
    )

}

export default FormGenerator